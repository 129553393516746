import * as React from "react";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { saveAs } from "file-saver";
import { Typography } from "@mui/material";

const Document = styled.div`
  padding-bottom: 5px;
  display: flex;
`;
interface IDocumentRenderer {
  documents: { url: string; name: string }[];
  deleteDocument?: (documentName: string) => void;
}
export const DocumentRendererComponent = React.memo(function RenderDocuments({
  documents,
  deleteDocument,
}: IDocumentRenderer) {
  const handleDeleteDocument = (document: any) => {
    if (deleteDocument) deleteDocument(document.name);
  };
  return (
    <div>
      {documents &&
        documents.map((document: { url: string; name: string }) => {
          return (
            <DocumentRenderer
              document={document}
              deleteDocument={() => handleDeleteDocument(document)}
            />
          );
        })}
    </div>
  );
});
const DocumentRenderer: React.FC<{
  document: { url: string; name: string };
  deleteDocument?: () => void;
}> = ({ document, deleteDocument }) => {
  const handleDeleteDocument = () => {
    if (deleteDocument) deleteDocument();
  };
  const name = document.name.split("%2F").pop()?.replace("%", " ");
  const handleDownload = (url: string, name: string) => {
    saveAs(url, name);
  };
  return (
    <Document>
      {name && (
        <>
          <FilePresentIcon />
          <Typography>
            <div onClick={() => handleDownload(document.url, name)}>{name}</div>
            {/* {document?.name} */}
          </Typography>
          <div onClick={() => handleDeleteDocument()}>
            <CloseIcon color="error" />
          </div>
        </>
      )}
    </Document>
  );
};
