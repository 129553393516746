import React from "react";
import { CommonDialog } from "../../../components/CommonDialog";
import { FileUpload } from "../../../components/FileUpload";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { FieldArray, useFormikContext } from "formik";
import { IVehicle, VEHICLE_DOCUMENTS } from "../../../services/vehicle-service";
import styled from "styled-components";
import { Grid, Typography, IconButton } from "@mui/material";
import { UserRoles, getFileName } from "../../../utils";
import { getCurrentUser } from "../../../services/auth-service";
import { TextFieldWrapper } from "../../../components/FormElements/TextFieldWrapper";
import { FormDivider } from "../../../components/styled/FormDivider";
import { useTranslation } from "react-i18next";

const VehicleDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
interface Props {
  open: boolean;
  handleClose: () => void;
}
export const VehicleDocuments: React.FC<Props> = ({ open, handleClose }) => {
  const {t} = useTranslation();
  const handleFormSubmit = () => {
    handleClose();
  };
  const user = getCurrentUser();
  const isClient = user?.role === UserRoles.CLIENT;
  const handleModalClose = () => {
    handleClose();
  };
  const { values, setFieldValue, handleChange, handleBlur } =
    useFormikContext<IVehicle>();
  return (
    <CommonDialog
      title={t("Vehicle Documents")}
      open={open}
      handleSubmit={handleFormSubmit}
      handleToggleOpen={handleModalClose}
    >
      <VehicleDocumentsContainer>
        {!isClient && (
          <>
            <Typography>{t("Title")}</Typography>
            <FileUpload
              onFileSelect={(file: any) =>
                setFieldValue(VEHICLE_DOCUMENTS.TITLE, file)
              }
              onDelete={() => setFieldValue(VEHICLE_DOCUMENTS.TITLE, null)}
              value={values?.title}
              dialogTitle={t("Upload title")}
              label={
                values?.title ? getFileName(values?.title) : t("Upload title")
              }
            />
            <Typography>{t("Bill of sale")}</Typography>
            <FileUpload
              onFileSelect={(file: any) =>
                setFieldValue(VEHICLE_DOCUMENTS.INVOICE, file)
              }
              onDelete={() => setFieldValue(VEHICLE_DOCUMENTS.INVOICE, null)}
              value={values?.invoice}
              dialogTitle={t("Upload bill of sale")}
              label={
                values?.invoice
                  ? getFileName(values?.invoice)
                  : t("Upload bill of sale")
              }
            />
            <Typography>{t("Motus invoice")}</Typography>
            <FileUpload
              onFileSelect={(file: any) =>
                setFieldValue(VEHICLE_DOCUMENTS.MOTUS_INVOICE, file)
              }
              onDelete={() =>
                setFieldValue(VEHICLE_DOCUMENTS.MOTUS_INVOICE, null)
              }
              value={values?.motusInvoice}
              dialogTitle={t("Upload Motus invoice")}
              label={
                values?.motusInvoice
                  ? getFileName(values?.motusInvoice)
                  : t("Upload Motus invoice")
              }
            />
          </>
        )}
        <Typography>{t("Techical report")}</Typography>
        <FileUpload
          onFileSelect={(file: any) =>
            setFieldValue(VEHICLE_DOCUMENTS.TECHNICAL_REPORT, file)
          }
          onDelete={() =>
            setFieldValue(VEHICLE_DOCUMENTS.TECHNICAL_REPORT, null)
          }
          dialogTitle={t("Upload technical report")}
          value={values?.technicalReport}
          label={
            values?.technicalReport
              ? getFileName(values?.technicalReport)
              : t("Upload technical report")
          }
        />
        <Typography>{t("Tax assesment")}</Typography>
        <FileUpload
          onFileSelect={(file: any) =>
            setFieldValue(VEHICLE_DOCUMENTS.TAX_ASSESSMENT, file)
          }
          onDelete={() => setFieldValue(VEHICLE_DOCUMENTS.TAX_ASSESSMENT, null)}
          dialogTitle={t("Upload tax assesment")}
          value={values.taxAssessment}
          label={
            values?.taxAssessment
              ? getFileName(values?.taxAssessment)
              : t("Upload tax assesment")
          }
        />
        <Typography>{t("Power of Attorney")}</Typography>
        <FileUpload
          onFileSelect={(file: any) =>
            setFieldValue(VEHICLE_DOCUMENTS.POA, file)
          }
          onDelete={() => setFieldValue(VEHICLE_DOCUMENTS.POA, null)}
          dialogTitle={t("Upload POA")}
          value={values.powerOfAttorney}
          label={
            values?.powerOfAttorney
              ? getFileName(values?.powerOfAttorney)
              : t("Upload POA")
          }
        />
        <FormDivider variant="fullWidth">{t("Other documents")}</FormDivider>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <FieldArray
              name="otherFiles"
              render={(arrayHelpers) => (
                <div>
                  {values.otherFiles?.map((status: any, index: number) => (
                    <Grid
                      style={{ paddingBottom: 10 }}
                      gap={1}
                      container
                      key={index}
                    >
                      {/** both these conventions do the same */}
                      <Grid item xs={12} md={3}>
                        <TextFieldWrapper
                          label={t("Document name")}
                          fullWidth
                          size="small"
                          value={
                            values.otherFiles[index]
                              ? values.otherFiles[index].name
                              : ""
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={`otherFiles[${index}].name`}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <FileUpload
                          onFileSelect={(file: any) =>
                            setFieldValue(`otherFiles[${index}].file`, file)
                          }
                          onDelete={() =>
                            setFieldValue(`otherFiles[${index}].file`, null)
                          }
                          dialogTitle={
                            values.otherFiles[index]
                              ? values.otherFiles[index].name
                              : t("Select file")
                          }
                          value={
                            values.otherFiles[index]
                              ? values.otherFiles[index].file
                              : null
                          }
                          label={
                            values.otherFiles[index] &&
                            values.otherFiles[index].file
                              ? getFileName(values.otherFiles[index].file)
                              : t("Select file")
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        {index > 0 && (
                          <IconButton
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <RemoveIcon fontSize="medium" />
                          </IconButton>
                        )}
                        {index + 1 === values?.otherFiles.length && (
                          <IconButton
                            type="button"
                            onClick={() =>
                              arrayHelpers.push({
                                status: "",
                              })
                            }
                          >
                            <AddIcon fontSize="medium" />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </div>
              )}
            />
          </Grid>
        </Grid>
      </VehicleDocumentsContainer>
    </CommonDialog>
  );
};
