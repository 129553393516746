import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CommonDialog } from "../../components/CommonDialog";
import { LoaderIcon } from "react-hot-toast";
import { useTruck } from "./hooks/useTruck";
import { TruckDetailsForm } from "./TruckDetailsForm";
import { IVehicle } from "../../services/vehicle-service";
import { ReactImageGalleryItem } from "react-image-gallery";
import { mapImagesToGalleryItems } from "../../utils";
import { VehicleDetailsForm } from "../Vehicles/VehicleDetailForm";
interface ITruckDetailsProps {
  handleToggleOpen?: () => void;
  open?: boolean;
}
const { useState, useEffect } = React;
export const TruckDetails: React.FC<ITruckDetailsProps> = ({
  open = false,
  handleToggleOpen,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, truckData] = useTruck(params.truckId);
  const [vehiclePreview, setVehiclePreview] = useState<IVehicle | null>(null);
  const [imagesState, setImages] = React.useState<ReactImageGalleryItem[]>([]);
  const selectVehicle = (vehicle: IVehicle) => {
    setVehiclePreview(vehicle);
  };
  useEffect(() => {
    if (vehiclePreview) {
      const mappedImages = mapImagesToGalleryItems(vehiclePreview.images || []);
      setImages(mappedImages);
    }
  }, [vehiclePreview]);
  const toggleOpenDialog = () => {
    if (handleToggleOpen) handleToggleOpen();
    else navigate(-1);
  };
  const handleClose = () => {
    return false;
  };
  return (
    <>
      {vehiclePreview && (
        <CommonDialog
          title={`Vehicle Details - ${vehiclePreview?.description}`}
          handleToggleOpen={() => {
            setVehiclePreview(null);
          }}
          open
          onClose={handleClose}
        >
          <VehicleDetailsForm images={imagesState} vehicle={vehiclePreview} />
        </CommonDialog>
      )}
      <CommonDialog
        title={`Truck Details - ${truckData?.plateNumber}`}
        handleToggleOpen={toggleOpenDialog}
        open
        onClose={handleClose}
      >
        {isLoading ? <LoaderIcon /> : <TruckDetailsForm selectVehicle={selectVehicle} truck={truckData} />}
      </CommonDialog>
    </>
  );
};
