import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { getSettings } from "../../services/settings-service";
import { useTranslation } from "react-i18next";
export const Profile = () => {
  const {t} = useTranslation();
  const [settings, setSettings] = useState<{ [key: string]: any }>({});
  const loadSettings = async () => {
    const { data: result } = await getSettings();
    setSettings(result.data);
  };
  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography sx={{ paddingBottom: 2 }} variant="h4">
        {t("Profile")}
      </Typography>
        <Grid container >
        <Grid item md={6} >
            <Typography sx={{ paddingBottom: 1 }} variant="h5">{t("Bank Details")}:</Typography>
            <b>{t("Beneficiary")}: </b>: Motus Shipping GmbH & Co. KG
            <Typography sx={{ paddingBottom: 1 }} variant="h6">{t("EUR Account")}:</Typography>


            <b>{t("Address")}: </b>Weser Elbe Sparkasse Bürgermeister-Smidt-Str. 24-30 27568 Bremerhaven <br/>

            <b>IBAN: </b>DE63 2925 0000 1020 6368 90 <br/>

            <b>Swift: </b> BRLADE21BRS <br/>

 

            <Typography sx={{ paddingBottom: 1 }} variant="h6">{t("USD Account")}:</Typography>


            <b>{t("Address")}: </b>Norddeutsche Landesbank Friedrichswall 10 30159 Hannover <br/>

            <b>IBAN: </b>DE49 2925 0000 1090 0032 00 <br/>

            <b>Swift: </b> NOLADE2HXXX <br/>


            <Typography sx={{ paddingBottom: 2 }} variant="h6">{t("Title of transfer")}: {t("Your invoice number")} ({t("visible within the red frame on the invoice")})</Typography>
        </Grid>
            <Grid item md={6} >
                <Typography sx={{ paddingBottom: 2 }} variant="h5">
                    {t("Contact Office")}:
                </Typography>
                Motus Shipping GmbH & Co. KG

                Weissenstein 9

                27574 Bremerhaven <br/>

                {t("VAT")}: DE361839132 <br/>

                {t("Commercial register")}: HRA 29861 HB <br/>

                Tel: +49 471 48 38 38 00 <br/>

                {t("Managing Director")}: Jagoda Koscielak
                <Typography sx={{ paddingBottom: 2 }} variant="h5">{t("Operating hours")}:</Typography>
                {t("Monday")} – {t("Friday")}: {t("8:00am – 5:00pm")}

                <div className="google-map-code">
                <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2372.42958447498!2d8.612988177612268!3d53.514384362135985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b6b149e3e722ab%3A0xbb6efcf9ed854ac!2sMotus-Shipping%20GmbH%20%26%20Co.KG!5e0!3m2!1s!2s!4v1718794169247!5m2!1s!2s" width="600" height="450" style={{
                    border: 0
                }} loading="lazy"></iframe>
        </div>
            </Grid>
        </Grid>
    </Box>
  );
};
