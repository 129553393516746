import axiosIntance from "../axiosInstance";
import axiosInstanceFiles from "../axiosInstanceFiles";
import { ITruck } from "../pages/Trucks";
interface ITrucks {
    count: number,
    data: ITruck[]
}
export const getTrucks = (status: string | null) => {
    return axiosIntance.get<ITrucks>(`/trucks`,{
        params: {
          status
        }
    });
};
export const getTruck = (truckId: string) => {
    return axiosIntance.get<ITruck>(`/trucks/${truckId}`);
};
export const uploadTruckDocuments = (truckId: string, formData: any) => {
    return axiosInstanceFiles.post(`/files/upload/documents/truck/${truckId}`, formData);
};
export const createTruck = (values: any) => {
    return axiosIntance.post<ITruck>("/trucks", values);
}
export const updateTruck = (truckId: string, values: any) => {
    return axiosIntance.put<ITruck>(`/trucks/${truckId}`, values);
}
export const deleteTruck = (truckId: string) => {
    return axiosIntance.delete<ITruck>(`/trucks/${truckId}`);
};