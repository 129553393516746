import * as React from "react";
import { object, string } from "yup";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import { Button, Grid, MenuItem } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ITruck } from ".";
import { VehicleSelect } from "../../components/FormElements/VehicleSelect";
import { deleteDocument, TruckStatuses, UserRoles } from "../../utils";
import { DocumentRendererComponent } from "../../components/DocumentRenderer";
import { isMobile } from "react-device-detect";
import { UserSelect } from "../../components/FormElements/UserSelect";
interface TruckFormProps {
  truckData?: ITruck;
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
}
export const TruckForm: React.FC<TruckFormProps> = ({
  formRef,
  truckData,
  handleSubmit,
}) => {
  let validationSchema = object({
    plateNumber: string().required("Plate number is required"),
    status: string().required(),
  });
  const truckValues: ITruck = {
    id: "",
    vehicles: [],
    documents: [] as any,
    status: truckData?.status,
    expectedDate: "",
  };
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={truckData || truckValues}
      onSubmit={async (values: ITruck) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        touched,
        errors,
      }) => (
        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="plateNumber"
                name="plateNumber"
                label="Plate Number"
                value={values.plateNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.plateNumber && Boolean(errors.plateNumber)}
                helperText={touched.plateNumber && errors.plateNumber}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <VehicleSelect
                useAsyncOptions
                handleChange={setFieldValue}
                initialValues={values.vehicles ? [...values.vehicles] : []}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <UserSelect
                role={[UserRoles.EMPLOYEE]}
                label="Receiver Warehouse"
                handleChange={(user) => setFieldValue("warehouseId", user?.id)}
                values={values?.warehouse}
                error={Boolean(errors.warehouseId)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                size="small"
                fullWidth
                id="status"
                name="status"
                label="Status"
                value={values.status}
                onChange={handleChange}
                error={touched.status && Boolean(errors.status)}
              >
                {Object.keys(TruckStatuses).map((key) => {
                  return (
                    <MenuItem id={key} key={key} value={key}>
                      {TruckStatuses[key]}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                size="small"
                fullWidth
                id="company"
                name="company"
                label="Truck Company"
                value={values.company}
                onChange={handleChange}
                error={touched.company && Boolean(errors.company)}
              >
                {Object.keys(TruckStatuses).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {TruckStatuses[key]}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6}>
                {!isMobile ? (
                  <DesktopDatePicker
                    // fullWidth

                    label="Expected date"
                    inputFormat="DD/MM/YYYY"
                    value={values.expectedDate}
                    onChange={(value) => {
                      setFieldValue("expectedDate", value);
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" {...params} />
                    )}
                  />
                ) : (
                  <MobileDatePicker
                    label="Expected date"
                    inputFormat="MM/DD/YYYY"
                    value={values.expectedDate}
                    onChange={(value) => {
                      setFieldValue("expectedDate", value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} md={6}>
              <Button variant="contained" component="label">
                Upload Documents
                <input
                  hidden
                  id="documents"
                  name="documents"
                  type="file"
                  accept="application/pdf, .png, .jpg, .jpeg"
                  onChange={(event) => {
                    const files = event.target.files;
                    if (files) {
                      const myFiles = Array.from(files);
                      const groupFiles = values.documents?.concat(myFiles);
                      setFieldValue("documents", groupFiles);
                    }
                  }}
                  multiple
                />
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <DocumentRendererComponent
                deleteDocument={(documentName) =>
                  deleteDocument(values.documents, documentName, setFieldValue)
                }
                documents={values.documents}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
