import { FormikProps } from "formik";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { CommonDialog } from "../../components/CommonDialog";
import { ShipperForm } from "./ShipperForm";
import {
  IShipper,
  createShipper,
  getShipper,
  updateShipper,
} from "../../services/shipper-service";
interface TruckFormProps {
  handleToggleOpen: () => void;
  submitCallback?: () => void;
  open?: boolean;
}
export const AuctionDialog: React.FC<TruckFormProps> = ({
  handleToggleOpen,
  submitCallback,
}) => {
  const formRef = useRef<FormikProps<any>>(null);
  const navigate = useNavigate();
  const { shipperId } = useParams();
  const [containerData, setContainerData] = useState<IShipper>();
  const [errors, setErrors] = useState<any>();
  const [openUserForm, setOpenUserForm] = useState(false);
  const loadUserData = useCallback(async () => {
    if (shipperId) {
      const { data } = await getShipper(shipperId);
      setContainerData(data);
      setOpenUserForm(true);
    }
  }, [shipperId]);
  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };
  const handleSubmit = async (values: any) => {
    if (!shipperId)
      toast.promise(createShipper(values), {
        loading: "Loading...",
        success: (res) => {
          if (submitCallback) submitCallback();
          navigate("/shippers");
          return `Successfully created shipper ${res.data.name}`;
        },
        error: ({ response }) => {
          setErrors(response.data?.message);
          return "Error when creating shipper";
        },
      });
    else {
      toast.promise(updateShipper(shipperId, values), {
        loading: "Loading...",
        success: (res) => {
          if (submitCallback) submitCallback();
          navigate("/shippers");
          return `Successfully updated auction ${res.data.name}`;
        },
        error: (res) => {
          return "Error when updating auction";
        },
      });
    }
  };
  useEffect(() => {
    if (shipperId) {
      loadUserData();
    } else {
      setOpenUserForm(true);
    }
  }, [shipperId, loadUserData]);

  return (
    <CommonDialog
      title={shipperId ? "Edit shipper" : "Add shipper"}
      open
      handleSubmit={handleFormSubmit}
      handleToggleOpen={handleToggleOpen}
    >
      {openUserForm && (
        <ShipperForm
          containerData={containerData}
          handleToggleOpen={handleToggleOpen}
          formRef={formRef}
          handleSubmit={handleSubmit}
        />
      )}
    </CommonDialog>
  );
};
