import { Grid, MenuItem, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { TextFieldWrapper } from "../../../components/FormElements/TextFieldWrapper";
import { getCurrentUser } from "../../../services/auth-service";
import { IVehicle } from "../../../services/vehicle-service";
import { ClearenceTypes, UserRoles } from "../../../utils";

interface VehicleTitleFieldsProps {
  handleChange: any;
  handleBlur: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: any;
}
export const VehicleShippingFields: React.FC<VehicleTitleFieldsProps> = () => {
  const {t} = useTranslation();
  const { values, handleBlur, handleChange, errors } =
    useFormikContext<IVehicle>();
  const user = getCurrentUser();
  const isClient = user?.role === UserRoles.CLIENT;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <TextField
          select
          size="small"
          fullWidth
          id="type"
          name="customsClearance"
          label={t("Customs clearance")}
          value={values.customsClearance}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.customsClearance)}
          helperText={errors.customsClearance}
        >
          {Object.keys(ClearenceTypes).map((key) => {
            return (
              <MenuItem key={key} value={key}>
                {t(ClearenceTypes[key])}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
      {!isClient && (
        <>
          <Grid item xs={12} md={6}>
            <TextFieldWrapper
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              id="atbPosNumber"
              name="atbPosNumber"
              label={t("ATB Position number")}
              value={values.atbPosNumber}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldWrapper
              size="small"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              id="atcNumber"
              name="atcNumber"
              label={t("ATC number")}
              value={values.atcNumber}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextFieldWrapper
              size="small"
              disabled
              fullWidth
              // onChange={handleChange}
              // onBlur={handleBlur}
              // fullWidth
              // id="atcNumber"
              // name="atcNumber"
              label={t("ATB number")}
              value={values.container?.atbNumber}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
