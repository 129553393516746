import { FormikProps } from "formik";
import React, { useRef } from "react";
import toast, { LoaderIcon } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { CommonDialog } from "../../components/CommonDialog";
import { getCurrentUser, logout } from "../../services/auth-service";
import { updatePassword } from "../../services/user-service";
import { PasswordForm } from "./PasswordForm";

type Props = {
  handleDialogSubmit: () => void;
};
export const PasswordDialog: React.FC<Props> = ({ handleDialogSubmit }) => {
  const {t} = useTranslation();
  const user = getCurrentUser();
  const [loading, setLoading] = React.useState(false);
  const formRef = useRef<FormikProps<any>>(null);
  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };
  const handleSubmit = async (data: any) => {
    if (!user) {
      return;
    }
    setLoading(true);
    toast.promise(updatePassword(user.email, data.password), {
      loading: "Loading...",
      success: (res) => {
        handleDialogSubmit();
        setTimeout(() => logout(), 2000);
        return `Password changed successfully. Please login with your new password`;
      },
      error: ({ response }) => {
        return "Error when updating password";
      },
    });
  };

  return (
    <CommonDialog
      maxWidth="xs"
      title={t("Change your pasword")}
      open
      handleSubmit={handleFormSubmit}
      handleToggleOpen={() => {}}
    >
      {!loading ? <PasswordForm formRef={formRef} handleSubmit={handleSubmit} /> : <LoaderIcon  />}
    </CommonDialog>
  );
};
