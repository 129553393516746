import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactImageGalleryItem } from "react-image-gallery";
import { CommonDialog } from "../../components/CommonDialog";
import { LoaderIcon } from "react-hot-toast";
import { VehicleDetailsForm } from "./VehicleDetailForm";
import { VehicleStatus, mapImagesToGalleryItems } from "../../utils";
import { useVehicle } from "./hooks/useVehicle";
import { useTranslation } from "react-i18next";
interface IVehicleDetailsProps {
  vehicleId?: string;
  handleToggleOpen?: () => void;
  open?: boolean;
  share?: boolean;
}
export const VehicleDetails: React.FC<IVehicleDetailsProps> = ({
  open = false,
  vehicleId,
  share,
  handleToggleOpen,
}) => {
  const {t} = useTranslation();
  const params = useParams();
  const [imagesState, setImages] = React.useState<ReactImageGalleryItem[]>([]);
  const [isLoading, vehicleData] = useVehicle(
    vehicleId || params.vehicleId,
    share
  );
  useEffect(() => {
    if (vehicleData) {
      const mappedImages = mapImagesToGalleryItems(vehicleData.images || []);
      setImages(mappedImages);
    }
  }, [vehicleData?.images, vehicleData]);
  const toggleOpenDialog = () => {
    if (handleToggleOpen) handleToggleOpen();
  };
  const handleClose = () => {
    return false;
  };

  const lastStatus = vehicleData?.statusHistory
    ? vehicleData?.statusHistory[vehicleData?.statusHistory.length - 1]
    : undefined;

  return (
    <CommonDialog
      fullWidth
      maxWidth="xl"
      title={` ${t("Vehicle Details")} ${vehicleData?.description} ${
        lastStatus
          ? ` - ${
              t(VehicleStatus[lastStatus.status as keyof typeof VehicleStatus])
            }`
          : ""
      }`}
      handleToggleOpen={toggleOpenDialog}
      open
      fullScreen
      onClose={handleClose}
    >
      {isLoading ? (
        <LoaderIcon />
      ) : (
        <VehicleDetailsForm
          share={share}
          vehicle={vehicleData}
          images={imagesState}
        />
      )}
    </CommonDialog>
  );
};
