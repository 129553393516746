import {
  Button,
  DialogActions,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useState } from "react";
import { object, string } from "yup";


import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { searchVehicle } from "../../services/vehicle-service";

const searchOptions = {
  vin: "vin",
  stockNumber: "stockNumber",
};
interface LoginFormProps {
  setOpenDialog?: (value: boolean) => void;
}
const validationSchema = object({
  vin: string().required("VIN is required"),
});
export const SearchForm: React.FC<LoginFormProps> = () => {
  const {t} = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState("vin");
  const handleSearch = async (values: any) => {
    try {
      const vehicle = await searchVehicle(
        values.vin,
        value === searchOptions.stockNumber
      );
      if (vehicle.data) {
        toast.success(t("Vehicle found! For more information contact Motus!"), {
          duration: 5000
        });
        // navigate({
        //   pathname: `/search-vehicle/${values.vin}`,
        //   search: `useStockNumber=${value === searchOptions.stockNumber ? 1 : 0}`
        // });
      } else {
        setErrorMessage(`Vehicle with VIN : ${values.vin} not found!`);
      }
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.response) {
          const { message } = error.response?.data;
          setErrorMessage(message);
          return;
        }
        setErrorMessage(error.message);
        return;
      }
      setErrorMessage("Server not responding");
      return;
    }
  };
  return (
    <Formik
      initialValues={{
        vin: "",
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values) => {
        handleSearch(values);
      }}
    >
      {({ values, handleChange, touched, handleBlur, errors }) => (
        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="vin"
                name="vin"
                label={t("Search inventory by VIN")}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.vin && Boolean(errors.vin)}
                helperText={touched.vin && errors.vin}
              />
            </Grid>
          </Grid>
          <Typography color="red">{errorMessage}</Typography>
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              autoFocus
              onClick={() => {}}
            >
              {t("Search")}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};
