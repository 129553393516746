import React, { useEffect, useCallback } from "react";
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IVehicle } from "../../../services/vehicle-service";
import { CustomDatePicker } from "../../../components/CustomDatePicker";
import Checkbox from "@mui/material/Checkbox";
import { TextFieldWrapper } from "../../../components/FormElements/TextFieldWrapper";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

interface VehicleTitleFieldsProps {
  handleChange: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: IVehicle;
}
export const VehicleTitleFields: React.FC<VehicleTitleFieldsProps> = () => {
  const {t} = useTranslation();
  const { values, handleChange, setFieldValue } = useFormikContext<IVehicle>();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={3}>
        <FormControlLabel
          control={
            <Checkbox
              style={{
                transform: "scale(1.2)",
              }}
              size="medium"
              onChange={(event) => {
                setFieldValue("titleReceived", event.target.checked);
              }}
              checked={values.titleReceived}
            />
          }
          label={t("Title received")}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={
            <Checkbox
              style={{
                transform: "scale(1.2)",
              }}
              size="medium"
              onChange={(event) => {
                setFieldValue("titleHandover", event.target.checked);
              }}
              checked={values.titleHandover}
            />
          }
          label={t("Title handed over")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomDatePicker
          label={t("Date received")}
          value={values.titleReceivedDate}
          handleChange={(value) => setFieldValue("titleReceivedDate", value)}
        />
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <CustomDatePicker
          label="Date sent"
          value={values.titleSentDate}
          handleChange={(value) => setFieldValue("titleSentDate", value)}
        />
      </Grid> */}
      <Grid item xs={12} md={6}>
        <TextFieldWrapper
          inputProps={{
            maxLength: 50,
          }}
          multiline
          size="small"
          fullWidth
          id="handedOverTo"
          name="handedOverTo"
          label={t("Handed over to")}
          value={values.handedOverTo}
          onChange={handleChange}
        ></TextFieldWrapper>
      </Grid>
    </Grid>
  );
};
