import { FormikProps } from "formik";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { CommonDialog } from "../../components/CommonDialog";
import {
  createUser,
  getUser,
  IUser,
  updatePassword,
  updateUser,
} from "../../services/user-service";
import { UserForm } from "./UserForm";
import { useTranslation } from "react-i18next";
interface TruckFormProps {
  handleToggleOpen: () => void;
  submitCallback?: () => void;
  open?: boolean;
}
export const UserDialog: React.FC<TruckFormProps> = ({
  handleToggleOpen,
  submitCallback,
}) => {
  const {t} = useTranslation();
  const formRef = useRef<FormikProps<any>>(null);
  const navigate = useNavigate();
  const { userId } = useParams();
  const [userData, setUserData] = useState<IUser>();
  const [errors, setErrors] = useState<any>();
  const [openUserForm, setOpenUserForm] = useState(false);
  const loadUserData = useCallback(async () => {
    if (userId) {
      const { data } = await getUser(userId);
      setUserData(data);
      setOpenUserForm(true);
    }
  }, [userId]);
  const changePassword = async (email: string, password: string) => {
    await updatePassword(email, password);
  };
  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };
  const handleSubmit = async (values: IUser) => {
    if (!userId)
      toast.promise(createUser(values), {
        loading: t("Loading..."),
        success: (res) => {
          if (submitCallback) submitCallback();
          navigate("/users");
          return `${t("Successfully created user")} ${res.data.email}`;
        },
        error: ({ response }) => {
          setErrors(response.data?.message);
          return t("Error when creating user");
        },
      });
    else {
      toast.promise(updateUser(userId, values), {
        loading: t("Loading..."),
        success: (res) => {
          if (submitCallback) submitCallback();
          navigate("/users");
          if (values.password) {
            changePassword(values.email, values.password);
          }
          return `${t("Successfully updated user")} ${res.data.email}`;
        },
        error: (res) => {
          console.log(res, "RES");
          return t("Error when updating user");
        },
      });
    }
  };
  useEffect(() => {
    if (userId) {
      loadUserData();
    } else {
      setOpenUserForm(true);
    }
  }, [userId, loadUserData]);

  return (
    <CommonDialog
      title={userId ? t("Edit user") : t("Add user")}
      open
      handleSubmit={handleFormSubmit}
      handleToggleOpen={handleToggleOpen}
    >
      {openUserForm && (
        <UserForm
          userData={userData}
          formRef={formRef}
          handleSubmit={handleSubmit}
        />
      )}
    </CommonDialog>
  );
};
