import { FormikProps } from "formik";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CommonDialog } from "../../components/CommonDialog";
import { TitleShippingForm } from "./TitleShippingForm";
import { IVehicle } from "../../services/vehicle-service";
import {
  ITitleShipping,
  createShipping,
  getShipping,
  updateShipping,
} from "../../services/title-shipping-service";
interface TruckFormProps {
  handleToggleOpen: () => void;
  submitCallback?: () => void;
  open?: boolean;
  shippingId?: string;
}
export const TitleShippingDialog: React.FC<TruckFormProps> = ({
  handleToggleOpen,
  submitCallback,
  shippingId,
}) => {
  const formRef = useRef<FormikProps<any>>(null);
  const navigate = useNavigate();
  const [shippingData, setShippingData] = useState<ITitleShipping>();
  const [errors, setErrors] = useState<any>();
  const [openUserForm, setOpenUserForm] = useState(false);
  const loadUserData = useCallback(async () => {
    if (shippingId) {
      const { data } = await getShipping(shippingId);
      setShippingData(data);
      setOpenUserForm(true);
    }
  }, [shippingId]);
  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };
  const handleSubmit = async (values: ITitleShipping) => {
    const valuesMod = {
      ...values,
      vehicleIds:
        values.vehicles &&
        values.vehicles.map((vehicle: IVehicle) => {
          return vehicle.id || vehicle._id;
        }),
    };
    if (!shippingId)
      toast.promise(createShipping(valuesMod), {
        loading: "Loading...",
        success: (res) => {
          if (submitCallback) submitCallback();
          navigate("/titles");
          return `Successfully created shipping ${res.data.name}`;
        },
        error: ({ response }) => {
          setErrors(response.data?.message);
          return "Error when creating shipping";
        },
      });
    else {
      toast.promise(updateShipping(shippingId, valuesMod), {
        loading: "Loading...",
        success: (res) => {
          if (submitCallback) submitCallback();
          navigate("/titles");
          return `Successfully updated shipping ${res.data.name}`;
        },
        error: (res) => {
          return "Error when updating shipping";
        },
      });
    }
  };
  useEffect(() => {
    if (shippingId) {
      loadUserData();
    } else {
      setOpenUserForm(true);
    }
  }, [shippingId, loadUserData]);

  return (
    <CommonDialog
      title={shippingId ? "Edit title shipping" : "Add title shipping"}
      open
      handleSubmit={handleFormSubmit}
      handleToggleOpen={handleToggleOpen}
    >
      {openUserForm && (
        <TitleShippingForm
          containerData={shippingData}
          handleToggleOpen={handleToggleOpen}
          formRef={formRef}
          handleSubmit={handleSubmit}
        />
      )}
    </CommonDialog>
  );
};
