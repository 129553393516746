import * as React from "react";
import styled from "styled-components";
import { mobileVersionWidth } from "../../utils";
const BasicMenuContainer = styled.div`
  display: flex;
  gap: 7px;
  @media screen and (max-width: ${mobileVersionWidth}px) {
    gap: 2px;
  }
`;
interface Props {
  children?: React.ReactNode;
}
export const ActionMenu: React.FC<Props> = ({ children }) => {
  return <BasicMenuContainer>{children}</BasicMenuContainer>;
};
