import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Checkbox,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getUsers, IUser } from "../../services/user-service";
import { TextFieldWrapper } from "./TextFieldWrapper";

import { UserRoles } from "../../utils";
import { useTranslation } from "react-i18next";
interface UserSelectProps {
  handleChange?: (field: string, value: any) => void;
  children?: React.ReactNode;
  title?: string;
  role?: UserRoles[];
  values?: IUser[] | undefined;
  error?: boolean;
  name?: string;
  label?: string;
  required?: boolean;
}
export const MultipleUserSelect: React.FC<UserSelectProps> = ({
  handleChange,
  values,
  role,
}) => {
  const {t} = useTranslation();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<undefined | IUser[]>(
    values || undefined
  );
  const [open, setOpen] = useState(false);
  const handleOpen = async () => {
    setOpen(true);

    const { data: usersData } = await getUsers(role);
    setUsers(usersData.data);
  };
  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: IUser,
    { selected }: AutocompleteRenderOptionState
  ) => {
    const checked = selectedUser?.find((vehicle: IUser) => {
      return vehicle.id === option.id;
    });
    return (
      <li {...props} key={option.id}>
        <Checkbox
          key={option.id}
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={Boolean(checked)}
        />
        {option.firstName + "-" + option.email}
      </li>
    );
  };
  useEffect(() => {
    if (values) setSelectedUser(values);
  }, [values]);
  return (
    <Autocomplete
      multiple
      open={open}
      size="small"
      onOpen={handleOpen}
      id="asynchronous-demo"
      onChange={(event, value) => {
        setSelectedUser(value);
        if (handleChange) handleChange("users", value);
      }}
      renderOption={renderOption}
      value={selectedUser}
      disableCloseOnSelect
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.firstName ?? "" + option.email}
      options={users}
      //   loading={loading}
      renderInput={(params) => (
        <TextFieldWrapper {...params} label={t("Select clients")} />
      )}
    />
  );
};
