import { Typography } from "@mui/material";
import { isObject } from "formik";
import { t } from "i18next";
import React from "react";
import toast from "react-hot-toast";
import { CommonDialog } from "../../components/CommonDialog";
import { FileUpload } from "../../components/FileUpload";
import {
  IVehicle,
  VEHICLE_DOCUMENTS,
  uploadVehicleDocuments,
} from "../../services/vehicle-service";
import { getFileName } from "../../utils";
interface IVehicleDetailsProps {
  open: boolean;
  data?: IVehicle;
  handleToggleOpen: () => void;
}
const { useState, useEffect } = React;
export const AddDocuments: React.FC<IVehicleDetailsProps> = ({
  handleToggleOpen,
  open,
  data,
}) => {
  const [invoice, setInvoice] = useState<any>(null);
  const [poa, setPoa] = useState<any>(null);
  const [title, setTitle] = useState<any>(null);
  const [personalId, setPersonalId] = useState<any>(null);
  const [shippingInvoice, setShippingInvoice] = useState<any>(null);
  const [paymentProof, setPaymentProof] = useState<any>(null);
  const dialogClose = () => {
    handleToggleOpen();
  };
  const handleFormSubmit = async () => {
    if (!data?.id) {
      return;
    }
    const formData = new FormData(); //formdata object
    if (isObject(invoice)) {
      formData.append(VEHICLE_DOCUMENTS.INVOICE, invoice[0]);
    }
    if (isObject(poa)) {
      formData.append(VEHICLE_DOCUMENTS.POA, poa[0]);
    }

    if (isObject(shippingInvoice)) {
      formData.append(VEHICLE_DOCUMENTS.SHIPPING_INVOICE, shippingInvoice[0]);
    }
    if (isObject(title)) {
      formData.append(VEHICLE_DOCUMENTS.TITLE, title[0]);
    }

    if (isObject(personalId)) {
      formData.append(VEHICLE_DOCUMENTS.PERSONAL_ID, personalId[0]);
    }

    if (isObject(paymentProof)) {
      formData.append(VEHICLE_DOCUMENTS.PAYMENT_PROOF, paymentProof[0]);
    }

    toast.promise(uploadVehicleDocuments(data?.id, formData), {
      loading: "Loading...",
      success: (res) => {
        dialogClose();
        return `Successfully uploaded`;
      },
      error: ({ response }) => {
        return "Error when uploading documents";
      },
    });
  };

  useEffect(() => {
    if (data?.invoice) {
      setInvoice(data?.invoice);
    }

    if (data?.powerOfAttorney) {
      setPoa(data?.powerOfAttorney);
    }

    if (data?.title) {
      setTitle(data?.title);
    }
    if (data?.personalId) {
      setPersonalId(data?.personalId);
    }

    if (data?.shippingInvoice) {
      setShippingInvoice(data?.shippingInvoice);
    }

    if (data?.paymentProof) {
      setPaymentProof(data?.paymentProof);
    }
  }, [data]);
  return (
    <CommonDialog
      title={t("Add your documents")}
      open={open}
      handleSubmit={handleFormSubmit}
      handleToggleOpen={dialogClose}
    >
      <Typography>{t("Bill of sale")}</Typography>
      <FileUpload
        onFileSelect={(file: any) => setInvoice(file)}
        onDelete={() => setInvoice(null)}
        value={invoice}
        label={getFileName(invoice) ?? t("Bill of sale")}
      />
      <Typography>{t("Power of Attorney")}</Typography>
      <FileUpload
        onFileSelect={(file: any) => setPoa(file)}
        onDelete={() => setPoa(null)}
        value={poa}
        label={getFileName(poa) ?? t("Power of Attorney")}
      />
      <Typography>{t("Shipping invoice")}</Typography>
      <FileUpload
        onFileSelect={(file: any) => setShippingInvoice(file)}
        onDelete={() => setShippingInvoice(null)}
        value={shippingInvoice}
        label={getFileName(shippingInvoice) ?? t("Shipping invoice")}
      />
      <Typography>{t("Title")}</Typography>
      <FileUpload
        onFileSelect={(file: any) => setTitle(file)}
        onDelete={() => setTitle(null)}
        value={title}
        label={getFileName(title) ?? t("Title")}
      />
      <Typography>{t("Personal ID")}</Typography>
      <FileUpload
        onFileSelect={(file: any) => setPersonalId(file)}
        onDelete={() => setPersonalId(null)}
        value={personalId}
        label={getFileName(personalId) ?? t("Personal ID")}
      />
      <Typography>{t("Payment proof")}</Typography>
      <FileUpload
        onFileSelect={(file: any) => setPaymentProof(file)}
        onDelete={() => setPaymentProof(null)}
        value={paymentProof}
        label={getFileName(paymentProof) ?? t("Payment proof")}
      />
    </CommonDialog>
  );
};
