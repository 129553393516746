import axiosIntance from "../axiosInstance";
export interface ISettings {
    data: {
        [key: string] : any
    }
}
export const getSettings = () => {
    return axiosIntance.get<ISettings>(`/settings`);
};
export const postSettings = (data: {[key: string]: any}) => {
    return axiosIntance.post<ISettings>(`/settings`, {data});
};