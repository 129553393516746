import { FormikProps } from "formik";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { ITruck } from ".";
import { CommonDialog } from "../../components/CommonDialog";
import {
  createTruck,
  getTruck,
  updateTruck,
  uploadTruckDocuments,
} from "../../services/truck-service";
import { IVehicle } from "../../services/vehicle-service";
import { TruckForm } from "./TruckForm";
interface TruckFormProps {
  handleToggleOpen: () => void;
  submitCallback?: () => void;
  open?: boolean;
}
export const TruckDialog: React.FC<TruckFormProps> = ({
  handleToggleOpen,
  submitCallback,
}) => {
  const formRef = useRef<FormikProps<any>>(null);
  const navigate = useNavigate();
  const { truckId } = useParams();
  const [truckData, setTruckData] = useState<ITruck>();
  const [loading, setLoading] = useState(false);
  const [openTruckForm, setOpenTruckForm] = useState(false);
  const loadTruckData = useCallback(async () => {
    if (truckId) {
      const { data } = await getTruck(truckId);
      setTruckData({
        ...data,
        documents: data.documents.map((document: any) => {
          return {
            name: document,
          };
        }),
      });
      setOpenTruckForm(true);
    }
  }, [truckId]);
  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };
  const handleSubmit = async (values: ITruck) => {
    try {
      const valuesMod = {
        ...values,
        vehicleIds: values.vehicles?.map((vehicle: IVehicle) => {
          return vehicle._id || vehicle.id;
        }),
        documents: undefined,
      };
      const { data: truck } = truckId
        ? await updateTruck(truckId, valuesMod)
        : await createTruck(valuesMod);
      if (values.documents) {
        const formData = new FormData();
        for (let i = 0; i < values.documents.length; i++) {
          formData.append("documents", values.documents[i]);
        }
        toast.promise(
          uploadTruckDocuments(truck._id ? truck._id : truck.id, formData),
          {
            loading: "Loading documents",
            success: "Documents uploaded",
            error: "Error when uploading documents",
          }
        );
      }
      if (submitCallback) submitCallback();
      navigate("/trucks");
      toast.success(`Truck ${truckId ? `updated` : `created`} successfully!`);
    } catch {
      toast.error("Truck creation failed!");
    }

    setLoading(false);
  };
  useEffect(() => {
    if (truckId) {
      loadTruckData();
    } else {
      setOpenTruckForm(true);
    }
  }, [truckId, loadTruckData]);
  return (
    <CommonDialog
      title={truckId ? "Edit truck" : "Add truck"}
      open
      handleSubmit={handleFormSubmit}
      handleToggleOpen={handleToggleOpen}
    >
      {openTruckForm && (
        <TruckForm
          truckData={truckData}
          formRef={formRef}
          handleSubmit={handleSubmit}
        />
      )}
    </CommonDialog>
  );
};
