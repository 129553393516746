import { FormikProps } from "formik";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { CommonDialog } from "../../components/CommonDialog";
import {
  createContainer,
  getContainer,
  IContainer,
  updateContainer,
} from "../../services/container-service";
import {
  IUser,
} from "../../services/user-service";
import { ContainerForm } from "./ContainerForm";
import { IVehicle } from "../../services/vehicle-service";
import { UserRoles } from "../../utils";
import { getCurrentUser } from "../../services/auth-service";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
interface TruckFormProps {
  handleToggleOpen: () => void;
  submitCallback?: () => void;
  open?: boolean;
}
export const ContainerDialog: React.FC<TruckFormProps> = ({
  handleToggleOpen,
  submitCallback,
}) => {
  const {t} = useTranslation();
  const formRef = useRef<FormikProps<any>>(null);
  const navigate = useNavigate();
  const { containerId } = useParams();
  const [containerData, setContainerData] = useState<IContainer>();
  const [errors, setErrors] = useState<any>();
  const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);
  const [openUserForm, setOpenUserForm] = useState(false);
  const loadUserData = useCallback(async () => {
    if (containerId) {
      const { data } = await getContainer(containerId);
      setContainerData(data);
      setOpenUserForm(true);
    }
  }, [containerId]);
  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };
  const user = getCurrentUser();
  const handleSubmit = async (values: IContainer) => {
    const valuesMod = {
      ...values,
      warehouseId:
        user?.role === UserRoles.ADMIN ? values.warehouseId : user?.id,
      vehicleIds:
        values.vehicles &&
        values.vehicles.map((vehicle: IVehicle) => {
          return vehicle.id || vehicle._id;
        }),
      userIds:
        values.users &&
        values.users.map((user: IUser) => {
          return user._id || user.id;
        }),
    };
    if (!containerId)
      toast.promise(createContainer(valuesMod), {
        loading: t("Loading..."),
        success: (res) => {
          if (submitCallback) submitCallback();
          navigate("/containers");
          return `${t("Successfully created container")} ${res.data.containerNumber}`;
        },
        error: ({ response }) => {
          setErrors(response.data?.message);
          return t("Error while creating container");
        },
      });
    else {
      toast.promise(updateContainer(containerId, valuesMod), {
        loading: t("Loading..."),
        success: (res) => {
          if (submitCallback) submitCallback();
          navigate("/containers");
          return `${t("Successfully updated container")} ${res.data.name}`;
        },
        error: (res) => {
          return t("Error while updating container");
        },
      });
    }
  };
  useEffect(() => {
    if (containerId) {
      loadUserData();
    } else {
      setOpenUserForm(true);
    }
  }, [containerId, loadUserData]);
  const dialogClose = () => {
    if (formRef.current?.dirty) {
      setConfirmDialogOpened(true);
    } else {
      handleToggleOpen();
    }
  };
  const closeDialogReally = () => {
    handleToggleOpen();
    setConfirmDialogOpened(false);
  };
  return (
    <>
      <CommonDialog
        title={containerId ? t("Edit Container") : t("Add Container")}
        open
        handleSubmit={handleFormSubmit}
        handleToggleOpen={dialogClose}
      >
        {openUserForm && (
          <ContainerForm
            containerData={containerData}
            handleToggleOpen={handleToggleOpen}
            formRef={formRef}
            handleSubmit={handleSubmit}
          />
        )}
      </CommonDialog>
      <ConfirmDialog
        title={t("Changes are unsaved!")}
        open={confirmDialogOpened}
        handleConfirm={closeDialogReally}
        handleClose={() => {
          setConfirmDialogOpened(false);
        }}
      >
        {t("Some of your changes are not saved, do you really want to close the form?")}
      </ConfirmDialog>
    </>
  );
};
