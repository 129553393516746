import { useState, useEffect } from "react";
import { getTruck } from "../../../services/truck-service";
import { ITruck } from "..";

export const useTruck = (truckId: string | undefined) => {
  // state to keep track of loading
  const [loadingData, setLoadingData] = useState(false);

  // state for data itself
  const [vehicleData, setVehicleData] = useState<ITruck | undefined>(undefined);

  const fetchData = async (searchString: string) => { // it could be vin or vehicleId
    try {
      setLoadingData(true)
        const vehicleData = await getTruck(searchString);
        if(vehicleData)
        setVehicleData(vehicleData.data);
        setLoadingData(false)
    } catch (error) {
      console.log("error", error);
    }
  };

  // effect to fetch data
  useEffect(() => {
    if(!truckId) {
        return;
    }
    fetchData(truckId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [truckId]);

  // return the data and loading state from this hook
  return [loadingData, vehicleData] as const;
};