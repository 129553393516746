import * as React from "react";
import { Grid, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { object, ref, string } from "yup";
type Props = {
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
};
export const PasswordForm: React.FC<Props> = ({ handleSubmit, formRef }) => {
  const validationSchema = object({
    password: string().required("Password is required"),
    repeatPassword: string()
      .required("Passwords must match")
      .oneOf([ref("password")], "Passwords must match"),
  });
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={{
        password: "",
        repeatPassword: "",
      }}
      onSubmit={async (values: any) => {
        handleSubmit(values);
      }}
    >
      {({ values, handleChange, handleBlur, touched, errors }) => (
        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                type="password"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="password"
                name="password"
                label="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="password"
                id="repeatPassword"
                name="repeatPassword"
                label="Repeat password"
                value={values.repeatPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.repeatPassword && Boolean(errors.repeatPassword)}
                helperText={touched.repeatPassword && errors.repeatPassword}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
