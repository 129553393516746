import { useState, useEffect } from "react";
import { IVehicle, searchVehicle } from "../../../services/vehicle-service";
import { useSearchParams } from "react-router-dom";

export const useVehicleByVin = (vin: string | undefined) => {
  // state to keep track of loading
  const [loadingData, setLoadingData] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const useStockNumber = searchParams.get("useStockNumber");
  // state for data itself
  const [vehicleData, setVehicleData] = useState<IVehicle | undefined>(undefined);
  const [error, setError] = useState(false);
  const fetchData = async (searchString: string) => { // it could be vin or vehicleId
    try {
      setLoadingData(true)
      const vehicleData = await searchVehicle(searchString, useStockNumber === "1");
      if(vehicleData)
      setVehicleData(vehicleData.data);
      setLoadingData(false)
    } catch (error) {
      setError(true)
      console.log("error", error);
    }
  };

  // effect to fetch data
  useEffect(() => {
    if(!vin) {
        return;
    }
    fetchData(vin);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vin]);

  // return the data and loading state from this hook
  return [loadingData, vehicleData, error] as const;
};