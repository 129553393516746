import React from "react";
import { LoaderIcon } from "react-hot-toast";
import { ReactImageGalleryItem } from "react-image-gallery";
import { useNavigate, useParams } from "react-router-dom";
import { CommonDialog } from "../../components/CommonDialog";
import { IVehicle } from "../../services/vehicle-service";
import { mapImagesToGalleryItems } from "../../utils";
import { VehicleDetailsForm } from "../Vehicles/VehicleDetailForm";
import { ContainerDetailsForm } from "./ContainerDetailsForm";
import { useContainer } from "./hooks/useContainer";
interface ITruckDetailsProps {
  handleToggleOpen?: () => void;
  open?: boolean;
  containerId?: string;
}
const { useState, useEffect } = React;
export const ContainerDetails: React.FC<ITruckDetailsProps> = ({
  open = false,
  containerId,
  handleToggleOpen,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, containerData] = useContainer(containerId ?? params.containerId);
  const [vehiclePreview, setVehiclePreview] = useState<IVehicle | null>(null);
  const [imagesState, setImages] = React.useState<ReactImageGalleryItem[]>([]);
  useEffect(() => {
    if (vehiclePreview) {
      const mappedImages = mapImagesToGalleryItems(vehiclePreview.images || []);
      setImages(mappedImages);
    }
  }, [vehiclePreview]);
  const toggleOpenDialog = () => {
    if (handleToggleOpen) handleToggleOpen();
    else navigate(-1);
  };
  const handleClose = () => {
    return false;
  };
  const selectVehicle = (vehicle: IVehicle) => {
    setVehiclePreview(vehicle);
  };
  return (
    <>
      {vehiclePreview && (
        <CommonDialog
          title={`Vehicle Details - ${vehiclePreview?.description}`}
          handleToggleOpen={() => {
            setVehiclePreview(null);
          }}
          open
          onClose={handleClose}
        >
          <VehicleDetailsForm images={imagesState} vehicle={vehiclePreview} />
        </CommonDialog>
      )}
      <CommonDialog
        title={`Container Details - ${containerData?.name}`}
        handleToggleOpen={toggleOpenDialog}
        open
        onClose={handleClose}
      >
        {isLoading ? (
          <LoaderIcon />
        ) : (
          <ContainerDetailsForm
            selectVehicle={selectVehicle}
            container={containerData}
          />
        )}
      </CommonDialog>
    </>
  );
};
