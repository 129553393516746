import * as React from "react";
import { object, string } from "yup";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import { Grid } from "@mui/material";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { VehicleSelect } from "../../components/FormElements/VehicleSelect";
import dayjs from "dayjs";
import { isMobile } from "react-device-detect";
import { ITitleShipping } from "../../services/title-shipping-service";

interface ContainerFormProps {
  containerData?: ITitleShipping;
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
}
export const TitleShippingForm: React.FC<ContainerFormProps> = ({
  formRef,
  containerData,
  handleSubmit,
}) => {
  let validationSchema = object({
    name: string().required("Tracking number is required"),
  });
  const containerValues = {
    dateSent: dayjs().format("YYYY-MM-DD"),
  } as ITitleShipping;
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={containerData || containerValues}
      onSubmit={async (values: any) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        touched,
        errors,
      }) => (
        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="name"
                name="name"
                label="Tracking number"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                // helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="courier"
                name="courier"
                label="Courier"
                value={values.courier}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.courier && Boolean(errors.courier)}
                // helperText={touched.name && errors.name}
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6}>
                {!isMobile ? (
                  <DesktopDatePicker
                    label="Date sent"
                    inputFormat="DD/MM/YYYY"
                    value={values.dateSent}
                    onChange={(value) => {
                      setFieldValue("dateSent", value);
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" {...params} />
                    )}
                  />
                ) : (
                  <MobileDatePicker
                    label="Date sent"
                    inputFormat="MM/DD/YYYY"
                    value={values.dateSent}
                    onChange={(value) => {
                      setFieldValue("dateSent", value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} md={6}>
              <VehicleSelect
                useAsyncOptions
                handleChange={setFieldValue}
                initialValues={values.vehicles ? [...values.vehicles] : []}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="notes"
                name="notes"
                label="Notes"
                value={values.notes}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.notes && Boolean(errors.notes)}
                // helperText={touched.name && errors.name}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
