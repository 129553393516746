import * as React from "react";
import { object, string } from "yup";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import { Grid, IconButton, InputAdornment, MenuItem, Tooltip } from "@mui/material";
import { IUser } from "../../services/user-service";
import { UserRoles } from "../../utils";
import { getCurrentUser } from "../../services/auth-service";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

interface TruckFormProps {
  userData?: IUser;
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
}
export const UserForm: React.FC<TruckFormProps> = ({
  formRef,
  userData,
  handleSubmit,
}) => {
  const {t} = useTranslation();
  const user = getCurrentUser();
  const [password, setPassword] = React.useState("");
  const roles = Object.keys(UserRoles).filter((role: any) => {
    if (!user?.isAdmin) {
      return role !== UserRoles.ADMIN;
    }
    return role;
  }) as Array<keyof typeof UserRoles>;
  let validationSchema = object({
    email: string()
      .required(t("E-mail is required"))
      .email(t("Must be a valid e-mail")),
    password: userData
      ? string()
      : string()
          .required(t("Password is required and minimum 6 symbols in length"))
          .min(6),
    firstName: string().required(t("Name is required")),
  });
  const userValues: any = {
    id: "",
    role: UserRoles.CLIENT,
  };

  const generatePassword = () => {
    let charset = "";
    let newPassword = "";

    charset += "!@#$%^&*()";
    charset += "0123456789";
    charset += "abcdefghijklmnopqrstuvwxyz";
    charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (let i = 0; i < 8; i++) {
        newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    setPassword(newPassword);
  };
  React.useEffect(() => {
    formRef.current?.setFieldValue("password", password)
  }, [password, formRef])
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={userData || userValues}
      onSubmit={async (values: IUser) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        touched,
        errors,
      }) => (
        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="nickname"
                name="nickname"
                label={t("Nickname")}
                required
                value={values.nickname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="firstName"
                name="firstName"
                required
                label={t("Name")}
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={t("Auto generate")}>
                          <IconButton onClick={() => generatePassword()}>
                            <DonutLargeIcon />
                          </IconButton>
                          </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="password"
                required
                name="password"
                label={t("Password")}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="role"
                name="role"
                label={t("Role")}
                value={values.role}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {roles.map((key) => {
                  return (
                    <MenuItem id={key} key={key} value={key}>
                      {UserRoles[key]}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                required
                id="email"
                name="email"
                label="E-mail"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="phone"
                name="phone"
                label={t("Phone")}
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.phone && Boolean(errors.phone)}
                helperText={touched.phone && errors.phone}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                size="small"
                required
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="language"
                name="language"
                label={t("Language")}
                value={values.language}
                onChange={handleChange}
                onBlur={handleBlur}
              >                          
                <MenuItem value="enUS"><ReactCountryFlag countryCode="US" svg  /> {t("English")}</MenuItem>
                <MenuItem value="plPL"><ReactCountryFlag countryCode="PL" svg  /> {t("Polish")}</MenuItem>
                <MenuItem value="deDE"><ReactCountryFlag countryCode="DE" svg  /> {t("German")}</MenuItem>
                </TextField>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
