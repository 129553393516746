import React from "react";
import { CommonDialog } from "../../components/CommonDialog";
import { toast } from "react-hot-toast";
import Typography from "@mui/material/Typography";
import { bulkUpdateVehicles, IVehicle } from "../../services/vehicle-service";
import { Grid, Link, MenuItem, TextField } from "@mui/material";
import { VehicleStatus } from "../../utils";
interface IVehicleDetailsProps {
  handleToggleOpen: () => void;
  submitCallback?: () => void;
  vehicles: IVehicle[];
}
export const VehicleStatusDialog: React.FC<IVehicleDetailsProps> = ({
  handleToggleOpen,
  submitCallback,
  vehicles,
}) => {
  const [status, setStatus] = React.useState<VehicleStatus>(
    VehicleStatus.DELIVERED
  );

  const handleSubmit = async () => {
    try {
      toast.promise(
        bulkUpdateVehicles(
          vehicles.map((vehicle: IVehicle) => vehicle.id),
          status
        ),
        {
          loading: "Loading...",
          success: (res) => {
            if (submitCallback) {
              submitCallback();
            }
            return `Successfully updated vehicles status to : TRANSIT`;
          },
          error: "Error when updating vehicle status",
        }
      );
    } catch {
      toast.error("Something went wrong");
    }
  };
  return (
    <CommonDialog
      maxWidth="sm"
      title="Bulk update vehicles status"
      open
      handleSubmit={handleSubmit}
      handleToggleOpen={handleToggleOpen}
    >
      <Grid container>
        <Grid item xs={12} md={4}>
          <TextField
            label="New status"
            fullWidth
            select
            size="small"
            name="status"
            value={status}
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const { value } = e.target;
              setStatus(value as VehicleStatus);
            }}
          >
            {(
              Object.keys(VehicleStatus) as Array<keyof typeof VehicleStatus>
            ).map((key) => {
              return (
                <MenuItem key={key} value={key}>
                  {VehicleStatus[key]}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
      </Grid>
      <Typography variant="h5">
        Set new status for the following vehicles :{" "}
      </Typography>
      {vehicles.map((vehicle: IVehicle, index: number) => {
        return (
          <Typography>
            <Link href={`/#/vehicles/view/${vehicle.id}`}>
              {`${index + 1}. ${vehicle.description} - ${vehicle.vin}`}
            </Link>
          </Typography>
        );
      })}
    </CommonDialog>
  );
};
