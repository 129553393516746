import React, { useRef, useState } from "react";
import { CommonDialog } from "../../components/CommonDialog";
import { toast } from "react-hot-toast";
import { FormikProps } from "formik";
import {
  ICreateVehicle,
  updateVehicleNotes,
} from "../../services/vehicle-service";
import { useVehicle } from "./hooks/useVehicle";
import { getCurrentUser } from "../../services/auth-service";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { NotesForm } from "./NotesForm";
interface IVehicleDetailsProps {
  vehicleId?: string;
  handleToggleOpen: () => void;
  submitCallback?: () => void;
}
export const NotesDialog: React.FC<IVehicleDetailsProps> = ({
  handleToggleOpen,
  submitCallback,
  vehicleId,
}) => {
  const formRef = useRef<FormikProps<any>>(null);
  const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);
  const [isLoading, vehicleData] = useVehicle(vehicleId);

  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };

  const handleSubmit = async (values: ICreateVehicle) => {
    if (vehicleId) await updateVehicleNotes(vehicleId, values.customerNotes);
    toast.success(`Vehicle updated successfully!`);
    if (submitCallback) submitCallback();
  };
  const dialogClose = () => {
    console.log(formRef.current?.dirty, "DIRT");
    if (formRef.current?.dirty) {
      setConfirmDialogOpened(true);
    } else {
      handleToggleOpen();
    }
  };
  const closeDialogReally = () => {
    handleToggleOpen();
    setConfirmDialogOpened(false);
  };
  return (
    <>
      <CommonDialog
        title={"Edit notes"}
        open
        handleSubmit={handleFormSubmit}
        handleToggleOpen={dialogClose}
      >
        {!isLoading && (
          <NotesForm
            data={vehicleData}
            formRef={formRef}
            handleSubmit={handleSubmit}
          />
        )}
      </CommonDialog>
      <ConfirmDialog
        title="Changes are unsaved!"
        open={confirmDialogOpened}
        handleConfirm={closeDialogReally}
        handleClose={() => {
          setConfirmDialogOpened(false);
        }}
      >
        Some of your changes are not saved, do you really want to close the
        form?
      </ConfirmDialog>
    </>
  );
};
