import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ImageGalleryComponent } from "../ImageGalleryComponent";

const GoogleDriveGalleryContainer = styled.div`
`;

interface Props {
  vin: string;
}
interface IGoogleDriveFile {
  id: string;
  kind: string;
  mimeType: string;
  name: string;
  original: string;
}
export const GoogleDriveGallery: React.FC<Props> = ({vin}) => {
  const [files, setFiles] = useState<IGoogleDriveFile[]>([]);
  const imageGoogleDriveLink = (id: string) => {
    return `https://drive.google.com/thumbnail?id=${id}&sz=s4000`;
  };
  const request = useCallback(async () => {
    const parentFolder = await axios.get(
      `https://www.googleapis.com/drive/v3/files?q='1mbxCXP4ayvaDgu8D8YsT2vx2hO4nv8Nz'+in+parents&key=${process.env.REACT_APP_GOOGLE_DRIVE_API_KEY}`
    );
    const { files: foldersResponse } = parentFolder?.data;
    const folder: IGoogleDriveFile[] = foldersResponse.filter((file: IGoogleDriveFile) => {
      return (
        file.mimeType === "application/vnd.google-apps.folder" &&
        file.name === vin.slice(-6)
      );
    });
    if (folder) {
      const folderImagesRequest = await axios.get(
        `https://www.googleapis.com/drive/v3/files?q='${folder[0].id}'+in+parents&key=${process.env.REACT_APP_GOOGLE_DRIVE_API_KEY}`
      );
      const { files: folderImages } = folderImagesRequest?.data;
      setFiles(
        folderImages
          .filter((file: IGoogleDriveFile) => {
            return file.mimeType === "image/jpeg";
          })
          .map((image: IGoogleDriveFile) => {
            return {
              ...image,
              original: imageGoogleDriveLink(image.id),
            };
          })
      );
    }
  }, []);
  useEffect(() => {
    request();
  }, [request]);
  return <GoogleDriveGalleryContainer>{files.length > 0 && <ImageGalleryComponent items={files} />}</GoogleDriveGalleryContainer>;
};
