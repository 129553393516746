import React, { useRef, useState } from "react";
import { CommonDialog } from "../../components/CommonDialog";
import { toast } from "react-hot-toast";
import { VehicleForm } from "./VehicleForm";
import { FormikProps } from "formik";
import {
  createVehicleMethod,
  ICreateVehicle,
  sendSignalMessage,
  updateVehicleMethod,
} from "../../services/vehicle-service";
import { useVehicle } from "./hooks/useVehicle";
import { getCurrentUser } from "../../services/auth-service";
import { IUser } from "../../services/user-service";
import { UserRoles } from "../../utils";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { useTranslation } from "react-i18next";
interface IVehicleDetailsProps {
  vehicleId?: string;
  handleToggleOpen: () => void;
  submitCallback?: () => void;
}
export const VehicleDialog: React.FC<IVehicleDetailsProps> = ({
  handleToggleOpen,
  submitCallback,
  vehicleId,
}) => {
  const {t} = useTranslation();
  const user = getCurrentUser();
  const formRef = useRef<FormikProps<any>>(null);
  const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);
  const [isLoading, vehicleData] = useVehicle(vehicleId);

  const handleFormSubmit = () => {
    const { current: form } = formRef;
    form?.validateForm();
    if (!form?.isValid) {
      form?.setErrors(form?.errors);
      return;
    }
    formRef.current?.handleSubmit();
  };

  const handleSubmit = async (values: ICreateVehicle) => {
    const { statusHistory } = values;
    const lastStatus = statusHistory.slice(-1)[0];
    if (vehicleId) {
      await updateVehicleMethod({
        ...values,
        createdBy: user?.id,
        status: lastStatus?.status,
      });
      toast.success(`Vehicle updated successfully!`);
    } else {
      await createVehicleMethod({
        ...values,
        createdBy: user?.id,
        status: lastStatus.status,
        warehouseId:
          user?.role === UserRoles.ADMIN ? values.warehouseId : user?.id,
        userIds:
          values.users &&
          values.users.map((user: IUser) => {
            return user._id || user.id;
          }),
      })
        .catch((error: any) => {
          const { response } = error;
          if (response?.status === 409) {
            toast.error(`Vehicle ${values.vin} already exists!`);
          }
          return response;
        })
        .then((res: any) => {
          toast.success(`Vehicle ${values.description} created successfully!`);
        });
    }
    if (submitCallback) submitCallback();
  };
  const dialogClose = () => {
    if (formRef.current?.dirty) {
      setConfirmDialogOpened(true);
    } else {
      handleToggleOpen();
    }
  };
  const closeDialogReally = () => {
    handleToggleOpen();
    setConfirmDialogOpened(false);
  };
  return (
    <>
      <CommonDialog
        fullScreen
        title={vehicleId ? t("Edit Vehicle") : t("Add Vehicle")}
        open
        handleSubmit={handleFormSubmit}
        handleToggleOpen={dialogClose}
      >
        {!isLoading && (
          <VehicleForm
            vehicleData={vehicleData}
            formRef={formRef}
            handleSubmit={handleSubmit}
          />
        )}
      </CommonDialog>
      <ConfirmDialog
        title={t("Changes are unsaved!")}
        open={confirmDialogOpened}
        handleConfirm={closeDialogReally}
        handleClose={() => {
          setConfirmDialogOpened(false);
        }}
      >
        {t("Some of your changes are not saved, do you really want to close theform?")}
      </ConfirmDialog>
    </>
  );
};
