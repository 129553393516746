import { useState, useEffect } from "react";
import { IContainer, getContainer } from "../../../services/container-service";

export const useContainer = (containerId: string | undefined) => {
  // state to keep track of loading
  const [loadingData, setLoadingData] = useState(false);

  // state for data itself
  const [containerData, setcontainerData] = useState<IContainer | undefined>(undefined);

  const fetchData = async (searchString: string) => { // it could be vin or vehicleId
    try {
      setLoadingData(true)
        const containerData = await getContainer(searchString);
        if(containerData)
        setcontainerData(containerData.data);
        setLoadingData(false)
    } catch (error) {
      console.log("error", error);
    }
  };

  // effect to fetch data
  useEffect(() => {
    if(!containerId) {
        return;
    }
    fetchData(containerId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerId]);

  // return the data and loading state from this hook
  return [loadingData, containerData] as const;
};