export const lightTheme = {
    body: '#FFF',
    text: '#363537',
    toggleBorder: '#FFF',
    background: '#fff',
    textColor: '#363a3f',
    blueBackground: 'rgba(0,162,170,.5)',
}
export const darkTheme = {
    blueBackground: 'rgba(0,162,170,.5)',
    body: '#363537',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    background: '#000',
    textColor: '#fff'
}