import React from "react";
import Grid from "@mui/material/Grid";
import { toast } from "react-hot-toast";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import CropFreeIcon from "@mui/icons-material/CropFree";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TourIcon from "@mui/icons-material/Tour";
import Tooltip from "@mui/material/Tooltip";
import { FormDivider } from "../../components/styled/FormDivider";
import { IContainer } from "../../services/container-service";
import { IVehicle } from "../../services/vehicle-service";
import dayjs from "dayjs";
import { trackContainer } from "../../utils";
interface IVehicleDetailsProps {
  container?: IContainer;
  selectVehicle: (vehicle: IVehicle) => void;
}
export const ContainerDetailsForm: React.FC<IVehicleDetailsProps> = ({
  container,
  selectVehicle,
}) => {
  const copyVin = (vehicle: IVehicle) => {
    navigator.clipboard.writeText(vehicle.vin)
    toast.success("Vin copied!");
  }
  
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormDivider variant="fullWidth">Container Information</FormDivider>
          <List aria-labelledby="nested-list-subheader">
            <ListItemButton onClick={() => {trackContainer(container?.shippingLine?.trackUrl, container?.name)}}>
              <Tooltip title="Container number">
                <ListItemIcon>
                  <CropFreeIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={container?.name} />
            </ListItemButton>
            <ListItemButton >
              <Tooltip title="Shipping line">
                <ListItemIcon>
                  <DirectionsBoatIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={container?.shippingLine?.name} />
            </ListItemButton>
            <ListItemButton>
              <Tooltip title="Destination">
                <ListItemIcon>
                  <TourIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={container?.destination} />
            </ListItemButton>
            <ListItemButton>
              <Tooltip title="Expected date">
                <ListItemIcon>
                  <CalendarMonthIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={dayjs(container?.expectedDate).format("YYYY-MM-DD")} />
            </ListItemButton>
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormDivider variant="fullWidth">Loaded Vehicles</FormDivider>
          <List aria-labelledby="nested-list-subheader">
            {container?.vehicles &&
              container.vehicles.map((vehicle: IVehicle) => {
                return (
                  <ListItemButton onClick={() => {selectVehicle(vehicle)}}>
                    <Tooltip title={vehicle.description}>
                      <ListItemIcon>
                        <DirectionsCarIcon color="primary" />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={`${vehicle.description} - ${vehicle.vin}`} />
                  </ListItemButton>
                );
              })}
          </List>
        </Grid>
      </Grid>
    </>
  );
};
