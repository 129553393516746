import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DarkModeIcon from "@mui/icons-material/DarkMode";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import InfoIcon from "@mui/icons-material/Info";
import LightModeIcon from "@mui/icons-material/LightMode";
import LogoutIcon from "@mui/icons-material/Logout";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import Box from "@mui/material/Box";
import * as locales from '@mui/material/locale';
import Tooltip from "@mui/material/Tooltip";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Themes } from "../../App";
import logo from "../../assets/images/logo.png";
import { getCurrentUser, logout } from "../../services/auth-service";
import { UserRoles, mobileVersionWidth } from "../../utils";
import { SettingsMenu } from "../SettingsMenu";

type SupportedLocales = keyof typeof locales;
const NavMenu = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 20px;
  white-space: nowrap;
  @media screen and (max-width: ${mobileVersionWidth}px) {
    flex-direction: row;
    gap: 17px;
  }
`;
const SidebarContainer = styled.section`
  width: 70px;
  height: 95vh;
  background-image: linear-gradient(to top, #1d1e20, rgba(0, 162, 170, 0.5));
  border-radius: 26px;
  padding: 30px 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (max-width: ${mobileVersionWidth}px) {
    height: auto;
    flex-direction: row;
    overflow-x: scroll;
    border-radius: 0;
    top: 0;
    z-index: 100;
    width: 100%;
  }
  a {
    color: #fff;
    padding: 8px;
    &.active {
      border-radius: 6px;
    }
  }
`;
const IconHolder = styled.div`
  display: flex;
  align-items: center;
`;
interface SidebarProps {
  themeToggler: () => void;
  setLocale: (value: SupportedLocales) => void;
  locale: SupportedLocales
  theme: string;
}
export const Sidebar: React.FC<SidebarProps> = ({ themeToggler, setLocale, locale, theme }) => {
  const user = getCurrentUser();
  const {t} = useTranslation();
  const renderTopMenu = () => {
    return (
      <NavMenu>
        <Tooltip title={t("Home")}>
            <Box
              component="img"
              sx={{
                maxWidth: { xs: 50, md: 50 },
              }}
              alt="The house from the offer."
              src={logo}
            />
        </Tooltip>
        <Tooltip title={t("Calendar")}>
          <NavLink to="/">
            <IconHolder>
              <CalendarMonthIcon fontSize="medium" />
            </IconHolder>
          </NavLink>
        </Tooltip>
        <Tooltip title={t("Vehicles")}>
          <NavLink to="/vehicles">
            <IconHolder>
              <DriveEtaIcon fontSize="medium" />
            </IconHolder>
          </NavLink>
        </Tooltip>
        {/* {user?.role === "ADMIN" && ( */}
          <Tooltip title={t("Containers")}>
            <NavLink to="/containers">
              <IconHolder>
                <DirectionsBoatIcon fontSize="medium" />
              </IconHolder>
            </NavLink>
          </Tooltip>
      </NavMenu>
    );
  };

  const renderBottomMenu = () => {
    return (
      <NavMenu style={{ cursor: "pointer" }}>
          <Tooltip disableInteractive title={t("Change language")}>
          <Select
          value={locale}
          onChange={(event: SelectChangeEvent) => {
            setLocale(event.target.value as SupportedLocales);
          }}
        >
          <MenuItem value="enUS"><ReactCountryFlag countryCode="US" svg  /></MenuItem>
          <MenuItem value="plPL"><ReactCountryFlag countryCode="PL" svg  /></MenuItem>
          <MenuItem value="deDE"><ReactCountryFlag countryCode="DE" svg  /></MenuItem>
        </Select>
        </Tooltip>
        {user?.role !== UserRoles.CLIENT && (
          <Tooltip title={t("Settings")}>
            <SettingsMenu />
          </Tooltip>
        )}
        <Tooltip
          style={{ cursor: "pointer" }}
          onClick={themeToggler}
          title={t("Toggle dark mode")}
        >
          {theme === Themes.LIGHT ? (
            <DarkModeIcon style={{ color: "#fff" }} fontSize="medium" />
          ) : (
            <LightModeIcon style={{ color: "#fff" }} fontSize="medium" />
          )}
        </Tooltip>
        <Tooltip title={t("Information")}>
          <NavLink to="/profile">
            <InfoIcon fontSize="medium" />
          </NavLink>
        </Tooltip>
        <Tooltip
          style={{ cursor: "pointer" }}
          onClick={() => {
            logout();
          }}
          title={t("Log out")}
        >
          <LogoutIcon style={{ color: "#fff" }} fontSize="medium" />
        </Tooltip>
      </NavMenu>
    );
  };
  return (
    <SidebarContainer>
      {renderTopMenu()}

      {/* Bottom menu */}
      {renderBottomMenu()}
    </SidebarContainer>
  );
};
