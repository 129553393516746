import { PaletteMode } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import { Toaster } from "react-hot-toast";
import { Route, Routes, useNavigate } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./components/globalStyles";
import { LoginDialog } from "./components/LoginDialog";
import { Sidebar } from "./components/Sidebar";
import { darkTheme, lightTheme } from "./components/Themes";
import { WindowContextProvider } from "./context/WindowContextProvider";
import { Auctions } from "./pages/Auctions";
import { Containers } from "./pages/Containers";
import { ContainerDetails } from "./pages/Containers/ContainerDetails";
import { Home } from "./pages/Home";
import { Settings } from "./pages/Settings";
import { Shippers } from "./pages/Shippers";
import { ShippingLines } from "./pages/ShippingLines";
import { Titles } from "./pages/Titles";
import { Trucks } from "./pages/Trucks";
import { TruckDetails } from "./pages/Trucks/TruckDetails";
import { Users } from "./pages/users";
import { PasswordDialog } from "./pages/users/PasswordDialog";
import { Vehicles } from "./pages/Vehicles";
import { SearchVehicle } from "./pages/Vehicles/SearchVehicle";
import { VehicleDetails } from "./pages/Vehicles/VehicleDetails";
import { getCurrentUser } from "./services/auth-service";

import * as locales from '@mui/material/locale';
import { ProtectedRoute } from "./components/ProtectedRoute";
import i18n from "./i18n";
import { Profile } from "./pages/Profile";

type SupportedLocales = keyof typeof locales;

const Wrapper = styled.section`
  display: flex;
  min-height: 100vh;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const MainContainer = styled.section`
  flex-grow: 1;
  padding: 20px 16px 30px 16px;
  .cardHeader {
    font-family: Open Sans;
  }
`;
export const Themes = {
  DARK: "dark",
  LIGHT: "light",
};

export const App = () => {
  const user = getCurrentUser();
  const localeStorage = localStorage.getItem("locale") as  any;
  const [locale, setLocale] = useState<SupportedLocales>(user?.language || localeStorage || 'enUS');
  const [userState, setUserState] = useState<any>(user);
  const [mode, setMode] = useState<PaletteMode>(
    localStorage.getItem("prefferDarkMode") === "1" ? "dark" : "light"
  );
  const navigate = useNavigate();
  const themeToggler = () => {
    mode === Themes.LIGHT ? setMode("dark") : setMode("light");
  };
  window.addEventListener("logout", () => {
    setUserState(null);
    localStorage.removeItem("accessToken");
  });
  
  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: [
            "Open Sans",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
        },
        palette: {
          mode,
          primary: {
            light: "#757ce8",
            main: "rgba(0,162,170,.5)",
            dark: "#002884",
            contrastText: "#fff",
          },
        },
      }, locales[locale]),
    [mode, locale]
  );
  useEffect(() => {
    i18n.changeLanguage(locale);
    localStorage.setItem("prefferedLanguage", locale)
    localStorage.setItem("user", JSON.stringify({...user, language: locale}))
  }, [locale])
  useEffect(() => {
    localStorage.setItem(
      "prefferDarkMode",
      (mode === Themes.DARK ? 1 : 0).toString()
    );
  }, [mode]);
  return (
    <ThemeProvider theme={mode === Themes.LIGHT ? lightTheme : darkTheme}>
      <Toaster />
      <MuiThemeProvider theme={theme}>
        {!userState && <LoginDialog />}
        <CssBaseline />
        <>
          <GlobalStyles />
          <Wrapper>
            <WindowContextProvider>
              {userState && userState.shouldChangePassword && (
                <PasswordDialog
                  handleDialogSubmit={() => {
                    navigate("/vehicles");
                  }}
                />
              )}
              <Sidebar setLocale={setLocale} locale={locale} theme={mode} themeToggler={themeToggler} />
              <MainContainer>
                <Routes>
                  {/* Routes for all users */}
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/search-vehicle/:vin"
                    element={<SearchVehicle />}
                  />
                  <Route path="/trucks" element={<Trucks />} />
                  <Route path="/containers" element={<Containers />} />
                  <Route path="/vehicles" element={<Vehicles />} />
                  <Route path="/vehicles/:action" element={<Vehicles />} />
                  <Route
                    path="/vehicles/:action/:vehicleId"
                    element={<Vehicles />}
                  />
                  <Route
                    path="/share/:vehicleId"
                    element={<VehicleDetails share />}
                  />
                  {/* Routes for admin users */}
                  <Route element={<ProtectedRoute isAllowed={user?.role !== "CLIENT"} />}>
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/:action" element={<Users />} />
                    <Route path="/users/:action/:userId" element={<Users />} />
                  </Route>
                  <Route path="/titles" element={<Titles />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route
                    path="/search-truck/:truckId"
                    element={<TruckDetails />}
                  />
                  <Route
                    path="/search-container/:containerId"
                    element={<ContainerDetails />}
                  />
                  <Route path="/vehicles/view/:vin" element={<Vehicles />} />
                  <Route path="/trucks/:action" element={<Trucks />} />
                  <Route path="/trucks/:action/:truckId" element={<Trucks />} />
                  <Route path="/containers/:action" element={<Containers />} />
                  <Route path="/shipping-lines" element={<ShippingLines />} />
                  <Route
                    path="/shipping-lines/:action"
                    element={<ShippingLines />}
                  />
                  <Route
                    path="/shipping-lines/:action/:shippingLineId"
                    element={<ShippingLines />}
                  />

                  <Route path="/ports" element={<Auctions />} />
                  <Route path="/ports/:action" element={<Auctions />} />
                  <Route
                    path="/ports/:action/:auctionId"
                    element={<Auctions />}
                  />
                  <Route path="/shippers" element={<Shippers />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route
                    path="/shippers/:action/:shipperId"
                    element={<Shippers />}
                  />
                  <Route path="/shippers/:action" element={<Shippers />} />
                  <Route
                    path="/containers/:action/:containerId"
                    element={<Containers />}
                  />
                  <Route
                    path="/containers/preview-vehicle/:vin"
                    element={<Containers />}
                  />
                  
                </Routes>
              </MainContainer>
            </WindowContextProvider>
          </Wrapper>
        </>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};
