import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import { GridToolbar } from "@mui/x-data-grid/components";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { GridActions } from "../../utils";
import { ActionMenu } from "../../components/BasicTable/ActionMenu";
import { TitleShippingDialog } from "./TitleShippingDialog";
import { IContainer } from "../../services/container-service";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { IVehicle } from "../../services/vehicle-service";
import styled from "styled-components";
import { Link } from "@mui/material";
import { getCurrentUser } from "../../services/auth-service";
import {
  ITitleShipping,
  deleteShipping,
  getShippings,
} from "../../services/title-shipping-service";
import { LoadedVehiclesDialog } from "../Containers/LoadedVehiclesDialog";
const ContainersGrid = styled.div`
   {
    height: calc(100vh - 150px);
    width: 100%;
    .loadedVehicles {
      justify-content: center !important;
      flex-direction: column;
      p {
        width: 100%;
      }
    }
  }
`;
export const Titles = () => {
  const [shippings, setContainers] = useState<ITitleShipping[]>([]);
  const [open, setOpen] = React.useState(false);
  const [titleToEdit, setTitleToEdit] = React.useState<string | undefined>(
    undefined
  );

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [containerToDelete, setContainerToDelete] = useState<
    IContainer | undefined
  >(undefined);
  const [previewVehicles, setPreviewVehicles] = useState<IVehicle[] | null>(
    null
  );
  const user = getCurrentUser();
  const renderVehicles = (vehicles: IVehicle[]) => {
    return vehicles ? (
      <Link
        style={{ cursor: "pointer" }}
        onClick={() => {
          setPreviewVehicles(vehicles);
        }}
      >
        View vehicles ({vehicles.length})
      </Link>
    ) : (
      0
    );
  };
  const actionColumn: GridColDef = {
    field: "date",
    sortable: false,
    // flex: 1,
    width: 130,
    headerAlign: "center",
    align: "center",
    headerName: "Actions",
    renderCell: (params: GridRenderCellParams<Date>) => (
      <ActionMenu>
        <IconButton onClick={() => handleToggleOpenDetails(params.row)}>
          <EditIcon fontSize="medium" />
        </IconButton>
        <IconButton onClick={() => handleDeleteContainer(params.row)}>
          <DeleteForeverIcon color="error" fontSize="medium" />
        </IconButton>
      </ActionMenu>
    ),
  };
  const columns: GridColDef[] = [
    { field: "name", headerName: "Tracking number", flex: 1 },
    {
      field: "vehicles",
      sortable: false,
      // flex: 1,
      width: 200,
      headerName: "Vehicles",
      cellClassName: "loadedVehicles",
      renderCell: (params: GridRenderCellParams) => {
        return renderVehicles(params.value);
      },
    },
    {
      field: "courier",
      align: "left",
      headerAlign: "left",
      headerName: "Courier",
      type: "string",
      renderCell: (params: GridRenderCellParams) => {
        return params.value?.name;
      },
      flex: 1,
    },
    {
      field: "dateSent",
      headerName: "Date sent",
      flex: 1,
      description: "This column has a value getter and is not sortable.",
      sortable: true,
      width: 150,
      valueFormatter: (params) => dayjs(params.value).format("DD/MM/YYYY"),
    },

    ...(user?.isAdmin ? [actionColumn] : []),
  ];

  const handleDeleteContainer = (container: IContainer) => {
    setContainerToDelete(container);
    setDeleteOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      if (containerToDelete) await deleteShipping(containerToDelete.id);
      toast.success("Shipping deleted successfully");
    } catch (error) {
      console.error(error, "ERROR");
      toast.error("Something went wrong");
    }
    loadContainers();
    setContainerToDelete(undefined);
    setDeleteOpen(false);
  };
  const handleToggleOpenDetails = (titleShipping?: ITitleShipping) => {
    setOpen(!open);
    setTitleToEdit(titleShipping?.id);
  };
  const handleToggleOpen = () => {
    setOpen(!open);
    setTitleToEdit(undefined);
  };
  const handleToggleOpenVehicleDetails = () => {
    setTitleToEdit(undefined);
  };
  const loadContainers = async () => {
    const { data: result } = await getShippings();
    setContainers(result.data);
  };
  useEffect(() => {
    loadContainers();
  }, []);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <GridActions>
        <Typography variant="h4">Titles Shipping</Typography>
        {user?.isAdmin && (
          <Button variant="contained" onClick={handleToggleOpen}>
            Add
          </Button>
        )}
        {open && (
          <TitleShippingDialog
            shippingId={titleToEdit}
            submitCallback={loadContainers}
            handleToggleOpen={handleToggleOpen}
          />
        )}
      </GridActions>
      <ConfirmDialog
        handleConfirm={handleConfirmDelete}
        handleClose={() => setDeleteOpen(false)}
        open={deleteOpen}
        title="Are you sure to delete this shipping?"
      >
        Deleting container <strong>{containerToDelete?.name}</strong>
      </ConfirmDialog>
      {previewVehicles && (
        <LoadedVehiclesDialog
          title="Titles sent"
          vehicles={previewVehicles}
          //submitCallback={loadVehicles}
          handleToggleOpen={() => {
            setPreviewVehicles(null);
          }}
        />
      )}
      <ContainersGrid>
        <DataGrid
          disableColumnFilter
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableSelectionOnClick
          rows={shippings}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5, 10, 20, 50]}
          checkboxSelection
        />
      </ContainersGrid>
    </Box>
  );
};
