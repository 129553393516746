import CancelIcon from '@mui/icons-material/Cancel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid/components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ActionMenu } from "../../components/BasicTable/ActionMenu";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { WindowContext } from "../../context/WindowContextProvider";
import { getCurrentUser } from "../../services/auth-service";
import { deleteUser, getUsers, IUser } from "../../services/user-service";
import { GridActions, GridTolbarLocale, UserRoles } from "../../utils";
import { UserDialog } from "./UserDialog";
const UserActions = {
  ADD: "add-user",
};
export const Users = () => {
  const {t} = useTranslation();
  const user = getCurrentUser();
  const columns: GridColDef[] = [
    {
      field: "nickname",
      headerName: t("Nickname"),
      flex: 1,
    },
    {
      field: "email",
      headerName: t("E-mail"),
      flex: 1,
    },
    {
      field: "firstName",
      headerName: t("Name"),
      flex: 1,
      renderCell: (params: GridRenderCellParams<IUser>) => {
        return `${params.row.firstName || ''}`;
      },
    },
    {
      field: "role",
      headerName: t("Role"),
      flex: 1,
      sortable: false,
      width: 150,
      renderCell: (params: GridRenderCellParams<Date>) => {
        return params.row.role === "WAREHOUSE"
          ? UserRoles.EMPLOYEE
          : params.row.value;
      },
    },
    {
      field: "shouldChangePassword",
      headerName: t("Active"),
      sortable: false,
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params: GridRenderCellParams<Date>) => {
        return params.row.shouldChangePassword === false || params.row.shouldChangePassword === undefined
          ? <CheckBoxIcon color="primary" />
          : <CancelIcon color="error" />
      },
    },
    {
      field: "date",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: t("Actions"),
      renderCell: (params: GridRenderCellParams<Date>) => (
        <ActionMenu>
          <IconButton onClick={() => handleToggleOpenDetails(params.row)}>
            <EditIcon fontSize="medium" />
          </IconButton>
          {user?.isAdmin && (
            <IconButton onClick={() => handleDeleteUser(params.row)}>
              <DeleteForeverIcon color="error" fontSize="medium" />
            </IconButton>
          )}
        </ActionMenu>
      ),
    },
  ];
  const params = useParams();
  const navigate = useNavigate();
  const { clientWidth } = useContext(WindowContext);
  const [open, setOpen] = useState(params.action === UserActions.ADD);
  const [users, setUsers] = useState<IUser[]>([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<IUser | undefined>(
    undefined
  );
  const handleDeleteUser = (user: IUser) => {
    setUserToDelete(user);
    setDeleteOpen(true);
  };
  const handleConfirmDelete = async () => {
    try {
      if (userToDelete) await deleteUser(userToDelete.id);
      toast.success("Container deleted successfully");
    } catch (error) {
      console.log(error, "ERROR");
      toast.error("Something went wrong");
    }
    loadUsers();
    setUserToDelete(undefined);
    setDeleteOpen(false);
  };
  const handleToggleOpenDetails = (user?: IUser) => {
    navigate(!params.truckId ? `/users/edit-user/${user?.id}` : "/users");
  };
  const handleToggleOpen = () => {
    navigate(!params.action ? "/users/add-user" : "/users");
  };
  const loadUsers = useCallback(async () => {
    const { data: usersData } = await getUsers();
    setUsers(usersData.data);
  }, []);
  React.useEffect(() => {
    loadUsers();
  }, [loadUsers]);
  useEffect(() => {
    setOpen(params.action === UserActions.ADD || !!params.userId);
  }, [params.action, params.userId]);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <GridActions>
        <Typography variant="h4">Users</Typography>
        { user?.role && [UserRoles.ADMIN, UserRoles.EMPLOYEE].includes(user?.role) && (
          <Button variant="contained" onClick={handleToggleOpen}>
            {t("Add user")}
          </Button>
        )}
      </GridActions>
      <ConfirmDialog
        handleConfirm={handleConfirmDelete}
        handleClose={() => setDeleteOpen(false)}
        open={deleteOpen}
        title={t("Are you sure to delete this user?")}
      >
        {t("Deleting user")} <strong>{` ${userToDelete?.nickname}`}</strong>
      </ConfirmDialog>
      {open && (
        <UserDialog
          submitCallback={loadUsers}
          handleToggleOpen={handleToggleOpen}
        />
      )}
      <div style={{ height: "calc(100vh - 150px)", width: "100%" }}>
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "firstName", sort: "asc" }],
            }
          }}
          localeText={GridTolbarLocale(t)}
          disableColumnFilter
          onSelectionModelChange={(itm) => console.log(itm)}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableSelectionOnClick
          rows={users}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[5, 10, 20, 50]}
        />
      </div>
    </Box>
  );
};
