import React from "react";
import { isMobile } from "react-device-detect";
import {
  DesktopDatePicker,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextFieldWrapper } from "../FormElements/TextFieldWrapper";

interface CustomDatePickerProps {
  handleChange: (value: any) => void;
  value?: string;
  label?: string;
}
export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  handleChange,
  value,
  label,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {!isMobile ? (
        <DesktopDatePicker
          label={label}
          inputFormat="DD/MM/YYYY"
          value={value}
          onChange={(value) => {
            handleChange(value);
          }}
          renderInput={(params) => (
            <TextFieldWrapper fullWidth size="small" {...params} />
          )}
        />
      ) : (
        <MobileDatePicker
          label={label}
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={(value) => {
            handleChange(value);
          }}
          renderInput={(params) => <TextFieldWrapper {...params} />}
        />
      )}
    </LocalizationProvider>
  );
};
