import React from "react";
import Grid from "@mui/material/Grid";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import DescriptionIcon from "@mui/icons-material/Description";
import CropFreeIcon from "@mui/icons-material/CropFree";
import Tooltip from "@mui/material/Tooltip";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { FormDivider } from "../../components/styled/FormDivider";
import { ITruck } from ".";
import dayjs from "dayjs";
import { TruckStatuses } from "../../utils";
import { IVehicle } from "../../services/vehicle-service";
interface IVehicleDetailsProps {
  truck?: ITruck;
  selectVehicle: (vehicle: IVehicle) => void;
}
export const TruckDetailsForm: React.FC<IVehicleDetailsProps> = ({ truck, selectVehicle }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormDivider variant="fullWidth">Truck Information</FormDivider>
          <List aria-labelledby="nested-list-subheader">
            <ListItemButton>
              <Tooltip title="Plate number">
                <ListItemIcon>
                  <CropFreeIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={truck?.plateNumber} />
            </ListItemButton>

            <ListItemButton onClick={() => {}}>
              <Tooltip title="Status">
                <ListItemIcon>
                  <DescriptionIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={truck?.status ? TruckStatuses[truck?.status] : ""}
              />
            </ListItemButton>
            <ListItemButton>
              <Tooltip title="Expected date">
                <ListItemIcon>
                  <CalendarMonthIcon color="primary" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText
                primary={
                  truck?.expectedDate
                    ? dayjs(truck.expectedDate).format("YYYY-MM-DD")
                    : ""
                }
              />
            </ListItemButton>
          </List>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormDivider variant="fullWidth">Loaded Vehicles</FormDivider>
          <List aria-labelledby="nested-list-subheader">
            {truck?.vehicles &&
              truck.vehicles.map((vehicle: IVehicle) => {
                return (
                  <ListItemButton onClick={() => selectVehicle(vehicle)}>
                    <Tooltip title={vehicle.description}>
                      <ListItemIcon>
                        <DirectionsCarIcon color="primary" />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={`${vehicle.description} - ${vehicle.vin}`} />
                  </ListItemButton>
                );
              })}
          </List>
        </Grid>
      </Grid>
    </>
  );
};
