import React, { useState, useEffect } from "react";
import { Autocomplete } from "@mui/material";
import { getUsers, IUser } from "../../services/user-service";
import { TextFieldWrapper } from "./TextFieldWrapper";
import { UserRoles } from "../../utils";
import { useTranslation } from "react-i18next";
interface UserSelectProps {
  handleChange?: (selectedUser: IUser | null) => void;
  children?: React.ReactNode;
  title?: string;
  role?: UserRoles[];
  values?: IUser | null;
  error?: boolean;
  name?: string;
  label?: string;
  required?: boolean;
}
export const UserSelect: React.FC<UserSelectProps> = ({
  error,
  required,
  handleChange,
  values,
  role,
  label,
}) => {
  const {t} = useTranslation()
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(
    values || null
  );
  const [open, setOpen] = useState(false);
  const onSelectUser = (event: React.SyntheticEvent, value: IUser | null) => {
    setSelectedUser(value);
    setOpen(false);
    if (handleChange) handleChange(value);
  };
  const handleOpen = async () => {
    setOpen(true);

    const { data: usersData } = await getUsers(role);
    setUsers(usersData.data);
  };
  useEffect(() => {
    if (values) setSelectedUser(values);
  }, [values]);
  return (
    <Autocomplete
      open={open}
      size="small"
      value={selectedUser}
      onClose={() => {
        setOpen(false);
      }}
      onOpen={handleOpen}
      id="checkboxes-tags-demo"
      options={users}
      disableCloseOnSelect
      onChange={onSelectUser}
      getOptionLabel={(option) => option.nickname || option.firstName}
      renderInput={(params) => (
        <TextFieldWrapper
          error={error}
          required={required}
          {...params}
          label={label ? label : t("Select assigned client")}
          placeholder={t("Search clients")}
        />
      )}
    />
  );
};
