import axiosIntance from "../axiosInstance";
import { IVehicle } from "./vehicle-service";
export interface ITitleShipping {
    id: string;
    name: string,
    notes?: string,
    courier: string;
    dateSent?: string;
    vehicles?: IVehicle[];
    vehicleIds?: string[],
  }
interface IContainersResponse {
    count: number,
    data: ITitleShipping[]
}
export const getShippings = () => {
    return axiosIntance.get<IContainersResponse>(`/titleShipping/`);
};
export const getShipping = (titleShippingId:string) => {
    return axiosIntance.get<ITitleShipping>(`/titleShipping/${titleShippingId}`);
};
export const createShipping = (values: ITitleShipping) => {
    return axiosIntance.post<ITitleShipping>(`/titleShipping/`, values);
};
export const updateShipping = (titleShippingId: string, values: ITitleShipping) => {
    return axiosIntance.put<ITitleShipping>(`/titleShipping/${titleShippingId}`, values);
};
export const deleteShipping = (titleShippingId: string) => {
    return axiosIntance.delete<ITitleShipping>(`/titleShipping/${titleShippingId}`);
};