import { useEffect, useState } from "react";
import { Button, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid/components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-hot-toast";
import IconButton from "@mui/material/IconButton";
import { GridActions, TruckStatuses, UserRoles } from "../../utils";
import dayjs from "dayjs";
import { ActionMenu } from "../../components/BasicTable/ActionMenu";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { QuickStatusFilter } from "../../components/QuickStatusFilter";
import { deleteTruck, getTrucks } from "../../services/truck-service";
import { ConfirmDialog } from "../../components/CommonDialog/ConfirmDialog";
import { IVehicle } from "../../services/vehicle-service";
import { TruckDialog } from "./TruckDialog";
import { LoadedVehiclesDialog } from "../Containers/LoadedVehiclesDialog";
import { getCurrentUser } from "../../services/auth-service";
import { IUser } from "../../services/user-service";

const TruckActions = {
  ADD: "add-truck",
};

const renderDetailsButton = (params: GridRenderCellParams) => {
  const { row: truckData } = params;
  return truckData.vehicles && truckData.vehicles.length;
};

export interface ITruck {
  id: string;
  _id?: string;
  status?: keyof typeof TruckStatuses;
  plateNumber?: string;
  company?: string;
  expectedDate?: string;
  documents: any[];
  warehouseId?: string;
  warehouse?: IUser;
  vehicles?: IVehicle[];
  vehicleIds?: IVehicle[];
}
const pageSize = 10;
export const Trucks = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();
  const page = searchParams.get("page") || "0";
  const [pageState, setPageState] = useState(
    Number(page) > 0 ? Number(page) : 0
  );
  const [open, setOpen] = useState(params.action === TruckActions.ADD);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [trucks, setTrucks] = useState<ITruck[]>([]);
  const [truckToDelete, setTruckToDelete] = useState<ITruck | undefined>(
    undefined
  );
  const [previewVehicles, setPreviewVehicles] = useState<IVehicle[] | null>(
    null
  );
  const activeStatus = searchParams.get("status");
  const renderVehicles = (vehicles: IVehicle[]) => {
    return vehicles ? (
      <Link
        style={{ cursor: "pointer" }}
        onClick={() => {
          setPreviewVehicles(vehicles);
        }}
      >
        View vehicles ({vehicles.length})
      </Link>
    ) : (
      0
    );
  };
  const user = getCurrentUser();
  const adminColumns: GridColDef[] = [
    {
      field: "date",
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Actions",
      renderCell: (params: GridRenderCellParams<Date>) => (
        <ActionMenu>
          <IconButton onClick={() => handleToggleOpenDetails(params.row)}>
            <EditIcon fontSize="medium" />
          </IconButton>
          {/* <IconButton>
            <VisibilityIcon fontSize="medium" />
          </IconButton> */}
          <IconButton onClick={() => handleDeleteTruck(params.row)}>
            <DeleteForeverIcon color="error" fontSize="medium" />
          </IconButton>
        </ActionMenu>
      ),
    },
  ];
  const statuses = Object.keys(TruckStatuses).map((key) => {
    return {
      key,
      name: TruckStatuses[key],
    };
  });
  const columns: GridColDef[] = [
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    { field: "plateNumber", headerName: "Plate number", width: 200 },
    {
      field: "truckCompany",
      align: "left",
      headerAlign: "left",
      headerName: "Company",
      type: "string",
      width: 200,
      renderCell: (params: GridRenderCellParams) => {
        return params.value?.name;
      },
    },
    {
      field: "expectedDate",
      headerName: "Expected date",
      sortable: true,
      width: 150,
      valueFormatter: (params) => dayjs(params.value).format("DD/MM/YYYY"),
    },
    {
      headerAlign: "center",
      field: "vehicles",
      sortable: false,
      width: 200,
      align: "center",
      headerName: "Vehicles",
      renderCell: (params: GridRenderCellParams) => {
        return renderVehicles(params.value);
      },
    },
    ...(user?.role !== UserRoles.CLIENT ? adminColumns : []),
  ];

  const handleToggleOpenDetails = (truck?: ITruck) => {
    navigate(!params.truckId ? `/trucks/edit-truck/${truck?.id}` : "/trucks");
  };
  const handleDeleteTruck = (truck: ITruck) => {
    setTruckToDelete(truck);
  };
  const submitDeleteTruck = async () => {
    if (truckToDelete)
      toast.promise(deleteTruck(truckToDelete?.id), {
        loading: "Deleting truck",
        success: (res) => {
          loadTrucks();
          setTruckToDelete(undefined);
          return `Successfully deleted truck`;
        },
        error: "Error when deleting truck",
      });
  };
  const handleToggleOpen = () => {
    navigate(!params.action ? "/trucks/add-truck" : "/trucks");
  };
  const loadTrucks = async (searchParams?: URLSearchParams) => {
    setLoading(true);
    const { data: trucksData } = await getTrucks(activeStatus);
    setTrucks(trucksData.data);
    setTotalCount(trucksData.count);
    setLoading(false);
  };
  useEffect(() => {
    loadTrucks(searchParams);
  }, [searchParams]);
  useEffect(() => {
    setOpen(params.action === TruckActions.ADD || !!params.truckId);
  }, [params.action, params.truckId]);

  const handleStatusClick = (status: string) => {
    if (status === activeStatus) {
      // 👇️ delete each query param
      searchParams.delete("status");

      // 👇️ update state after
      setSearchParams(searchParams);
      return;
    }
    setSearchParams({ status });
  };
  const onPageChange = (page: number) => {
    console.log();
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
    setPageState(page);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <GridActions>
        <Typography variant="h4">Trucks</Typography>
        <Button variant="contained" onClick={handleToggleOpen}>
          Add Truck
        </Button>
      </GridActions>
      <GridActions>
        <QuickStatusFilter
          activeStatus={activeStatus}
          onClick={handleStatusClick}
          statuses={statuses}
        />
        <div>
          {open && (
            <TruckDialog
              submitCallback={loadTrucks}
              handleToggleOpen={handleToggleOpen}
            />
          )}
          <ConfirmDialog
            handleConfirm={() => submitDeleteTruck()}
            handleClose={() => setTruckToDelete(undefined)}
            open={truckToDelete !== undefined}
            title="Are you sure to delete this truck?"
          >
            Deleting truck <strong>{truckToDelete?.plateNumber}</strong>
          </ConfirmDialog>
          {previewVehicles && (
            <LoadedVehiclesDialog
              vehicles={previewVehicles}
              //submitCallback={loadVehicles}
              handleToggleOpen={() => {
                setPreviewVehicles(null);
              }}
            />
          )}
        </div>
      </GridActions>
      <div style={{ height: "calc(100vh - 200px)", width: "100%" }}>
        <DataGrid
          components={{ Toolbar: GridToolbar }}
          onPageChange={onPageChange}
          rowCount={totalCount}
          loading={loading}
          pagination
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableSelectionOnClick
          rows={trucks}
          page={pageState}
          columns={columns}
          pageSize={pageSize}
        />
      </div>
    </Box>
  );
};
