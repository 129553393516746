import * as React from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
const DialogContainer = styled.div`
  padding: 20px;
`;
interface CommonDialogProps {
  handleToggleOpen: () => void;
  handleSubmit?: () => void;
  children?: React.ReactNode;
  open: boolean;
  title?: string;
}
export const CommonDialog: React.FC<CommonDialogProps & DialogProps> = ({
  open,
  title,
  handleToggleOpen,
  children,
  handleSubmit,
  fullScreen,
}) => {
  const {t} = useTranslation();
  const handleClose = (event: {}, reason: string) => {
    if (reason && reason === "backdropClick") return;
    handleToggleOpen();
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            sx={{ boxShadow: 5 }}
            edge="start"
            onClick={handleToggleOpen}
            color="inherit"
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title ? t(title) : ""}
          </Typography>
          {handleSubmit && (
            <Button
              variant="contained"
              sx={{ boxShadow: 5 }}
              autoFocus
              onClick={handleSubmit}
            >
              {t("Save")}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <DialogContainer>{children}</DialogContainer>
    </Dialog>
  );
};
