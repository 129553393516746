import axiosIntance from "../axiosInstance";
import * as locales from '@mui/material/locale';
import { UserRoles } from "../utils";
export type UserRolesType = "ADMIN" | "USER";
export interface IUser {
    id: string,
    _id?: string,
    nickname?: string,
    econtOffice?: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    balanceSheetUrl?: string,
    role: UserRoles,
    isAdmin?: boolean,
    password?: string,
    language?: keyof typeof locales,
}
interface IUsersResponse {
    count: number,
    data: IUser[]
}
export const getUsers = (roles?: UserRoles[]) => {
    return axiosIntance.get<IUsersResponse>(`/users`, {
    params: {
        role: roles?.join(",")
    }});
};
export const getUser = (userId: string) => {
    return axiosIntance.get<IUser>(`/users/${userId}`);
};
export const createUser = (values: IUser) => {
    return axiosIntance.post<IUser>(`/auth/signup`, values);
};
export const updateUser = (userId: string, values: IUser) => {
    return axiosIntance.put<IUser>(`/users/${userId}`, values);
};
export const deleteUser = (userId: string) => {
    return axiosIntance.delete<IUser>(`/users/${userId}`);
};
export const updatePassword = (email: string, password: string) => {
    return axiosIntance.put<IUser>(`/auth/password/update`, {email, password});
};