
import {useEffect, useState, useCallback} from "react";
import TextField, {TextFieldProps} from "@mui/material/TextField";
import { useDebouncedCallback } from "use-debounce";

export const TextFieldWrapper = (props: TextFieldProps) => {
    const [innerValue, setInnerValue] = useState('');
  
    useEffect(() => {
      if (props.value) {
        setInnerValue(props.value as string);
      } else {
        setInnerValue('');
      }
    }, [props.value]);
  
    const debouncedHandleOnChange= useDebouncedCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
          props.onChange(event);
        }
      },
      300
    );
  
    const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
  
      const newValue = event.currentTarget.value;
      setInnerValue(newValue);
      debouncedHandleOnChange(event);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    return (
      <TextField
        inputProps={{ maxLength: props.inputProps?.maxLength || 50 }}
        {...props}
        value={innerValue}
        onChange={handleOnChange}
      />
    );
  };