import Grid from "@mui/material/Grid";
import styled from "styled-components";
import { Card, Typography } from "@mui/material";
import { EventCalendar } from "../../components/EventCalendar";
import { useTranslation } from "react-i18next";

const VinCard = styled.div``;
export const DesktopLayout = () => {
  const {t} = useTranslation();
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }} elevation={10}>
            <VinCard>
            <Typography style={{paddingBottom: 10}} variant="h4">{t("Calendar")}</Typography>
              <EventCalendar />
            </VinCard>
          </Card>
        </Grid>
        {/* {cards.map((item, index) => {
          return (
            <Grid key={item.title + index} item lg={3} sm={6} xl={3} xs={12}>
              <KPIChart {...item} />
            </Grid>
          );
        })} */}
      </Grid>
      <Grid marginTop={2} container spacing={4}>
        {/* <Grid item xs={12} md={6}>
          <Card elevation={10}>
            <CardContent>
              <BasicTable />
            </CardContent>
          </Card>
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <Card sx={{ p: 1 }} elevation={10}>
            <VinCard>
              <Typography
                fontSize={24}
                className="cardHeader"
                color="textSecondary"
                gutterBottom
              >
                Search by vin here
              </Typography>
            </VinCard>
          </Card>
        </Grid> */}
      </Grid>
    </>
  );
};
