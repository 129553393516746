import React from "react";
import { Grid } from "@mui/material";
import { IVehicle } from "../../../services/vehicle-service";
import { CustomDatePicker } from "../../../components/CustomDatePicker";
import { TextFieldWrapper } from "../../../components/FormElements/TextFieldWrapper";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

interface VehicleTitleFieldsProps {
  handleChange: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: any;
}
export const VehiclePickupInformation: React.FC<
  VehicleTitleFieldsProps
> = () => {
  const {t} = useTranslation();
  const { values, setFieldValue, handleBlur, handleChange } =
    useFormikContext<IVehicle>();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <CustomDatePicker
          label={t("Pickup date")}
          value={values.pickupDate}
          handleChange={(value) => setFieldValue("pickupDate", value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldWrapper
          size="small"
          fullWidth
          id="type"
          name="truckCompany"
          label={t("Company")}
          value={values.truckCompany}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldWrapper
          size="small"
          fullWidth
          id="type"
          name="driverName"
          label={t("Driver name")}
          value={values.driverName}
          onChange={handleChange}
          onBlur={handleBlur}
          //   error={Boolean(errors.type)}
          //   helperText={errors.type}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldWrapper
          size="small"
          fullWidth
          id="type"
          name="licensePlate"
          label={t("License plate")}
          value={values.licensePlate}
          onChange={handleChange}
          onBlur={handleBlur}
          //   error={Boolean(errors.type)}
          //   helperText={errors.type}
        />
      </Grid>
    </Grid>
  );
};
