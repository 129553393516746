import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReactImageGalleryItem } from "react-image-gallery";
import { CommonDialog } from "../../components/CommonDialog";
import { LoaderIcon } from "react-hot-toast";
import { VehicleDetailsForm } from "./VehicleDetailForm";
import { mapImagesToGalleryItems } from "../../utils";
import { useVehicleByVin } from "./hooks/useVehicleByVin";
interface IVehicleDetailsProps {
  handleToggleOpen?: () => void;
  open?: boolean;
}
export const SearchVehicle: React.FC<IVehicleDetailsProps> = ({
  open = true,
  handleToggleOpen,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const [imagesState, setImages] = React.useState<ReactImageGalleryItem[]>([]);
  const [isLoading, vehicleData, error] = useVehicleByVin(params.vin);
  useEffect(() => {
    if (vehicleData) {
      const mappedImages = mapImagesToGalleryItems(vehicleData.images || []);
      setImages(mappedImages);
    }
  }, [vehicleData?.images, vehicleData]);
  const toggleOpenDialog = () => {
    if (handleToggleOpen) handleToggleOpen();
    else navigate("/");
  };
  const handleClose = () => {
    navigate("/vehicles");
  };
  if (error) {
    navigate("/");
  }
  return (
    <CommonDialog
      fullScreen
      title={`Vehicle Details ${vehicleData?.description}`}
      handleToggleOpen={toggleOpenDialog}
      open={open}
      onClose={handleClose}
    >
      {isLoading ? (
        <LoaderIcon />
      ) : (
        <VehicleDetailsForm vehicle={vehicleData} images={imagesState} />
      )}
    </CommonDialog>
  );
};
