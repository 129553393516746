import * as React from "react";
import ImageGallery, { ReactImageGalleryItem, ReactImageGalleryProps } from "react-image-gallery";

interface IGalleryProps {
  removeImage?: (item: ReactImageGalleryItem) => void;
}
export const ImageGalleryComponent = React.memo(function Images(
  props: IGalleryProps & ReactImageGalleryProps
) {
  return <ImageGalleryRenderer removeImage={props.removeImage} {...props} />;
});
const ImageGalleryRenderer: React.FC<
  IGalleryProps & ReactImageGalleryProps
> = ({ items, showThumbnails = true, removeImage }) => {
  if(!items) {
    return <></>
  }
  return (
    <ImageGallery
      
      lazyLoad
      showPlayButton={false}
      showThumbnails={false}
      // renderItem={renderGalleryItem}
      // renderThumbInner={renderGalleryThumbnail}
      items={items}
    />
  );
};
