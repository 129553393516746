import * as React from "react";
import { object, string } from "yup";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import { Grid } from "@mui/material";
import { IVehicle } from "../../services/vehicle-service";

interface FormProps {
  data?: IVehicle;
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
}
export const NotesForm: React.FC<FormProps> = ({
  formRef,
  data,
  handleSubmit,
}) => {
  let validationSchema = object({
    customerNotes: string().required("Notes is required"),
  });
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={
        data ||
        ({
          customerNotes: "",
        } as IVehicle)
      }
      onSubmit={async (values: any) => {
        handleSubmit(values);
      }}
    >
      {({ values, handleChange, handleBlur, touched, errors }) => (
        <Form autoComplete="off">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="customerNotes"
                name="customerNotes"
                label="Notes"
                value={values.customerNotes}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.customerNotes && Boolean(errors.customerNotes)}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
