import axiosIntance from "../axiosInstance";
export interface IAuction {
    id: string,
    name: string,
    trackingUrl: string,
    url?: string,
  }
export interface IAuctionResponse {
    count: number,
    data: IAuction[]
}
export const getAuctions = () => {
    return axiosIntance.get<IAuctionResponse>(`/auctions/`);
};
export const getAuction = (auctionId: string) => {
    return axiosIntance.get<IAuction>(`/auctions/${auctionId}`);
};
export const createAuction = (values: any) => {
    return axiosIntance.post<IAuction>("/auctions", values);
}
export const updateAuction = (auctionId: string, values: any) => {
    return axiosIntance.put<IAuction>(`/auctions/${auctionId}`, values);
}
export const deleteAuction = (truckId: string) => {
    return axiosIntance.delete<IAuction>(`/auctions/${truckId}`);
};