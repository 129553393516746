import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { FieldArray, Form, Formik } from "formik";
import React from "react";
import { object, string } from "yup";

import { MuiTextFieldProps } from "@mui/x-date-pickers/internals";
import updateLocale from 'dayjs/plugin/updateLocale'; // ES 2015
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { MultipleUserSelect } from "../../components/FormElements/MultipleUserSelect";
import { TextFieldWrapper } from "../../components/FormElements/TextFieldWrapper";
import { UserSelect } from "../../components/FormElements/UserSelect";
import { FormDivider } from "../../components/styled/FormDivider";
import { getCurrentUser } from "../../services/auth-service";
import { IUser } from "../../services/user-service";
import {
  decodeVinNhtsa,
  ICreateVehicle,
  IVehicle,
  StatusHistory,
} from "../../services/vehicle-service";
import {
  decodeVin,
  UserRoles,
  VehicleStatus,
  VehicleTypes,
} from "../../utils";
import { VehicleDocuments } from "./FormGroups/VehicleDocuments";
import { VehiclePickupInformation } from "./FormGroups/VehiclePickupInformation";
import { VehicleShippingFields } from "./FormGroups/VehicleShippingFields";
import { VehicleTitleFields } from "./FormGroups/VehicleTitleFields";

require('dayjs/locale/de');
require('dayjs/locale/pl')
dayjs.extend(updateLocale)

interface VehicleFormProps {
  handleToggleOpen?: () => void;
  handleSubmit: (values: any) => void;
  open?: boolean;
  formRef: any;
  vehicleData?: IVehicle;
}
export const VehicleForm: React.FC<VehicleFormProps> = ({
  formRef,
  vehicleData,
  handleSubmit,
}) => {
  const {t} = useTranslation();
  const [openDocModal, setOpenDocModal] = React.useState(false);
  let validationSchema = object({
    type: string().required(),
    description: string().required(t("Description is required")),
  });
  // @ts-ignore
  const initialValues = {
    customsClearance: undefined,
    expectedDate: null,
    titleReceivedDate: null,
    titleSentDate: null,
    status: "NEW",
    keys: true,
    pickupDate: null,
    type: "Car",
    statusHistory: [
      {
        date: dayjs().format("YYYY-MM-DD"),
        status: "NEW",
      },
    ],
    otherFiles: [{ name: "", file: "" }],
  } as ICreateVehicle;
  const handleVinChange = async (
    props: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const { value } = props.target;
    setFieldValue("vin", value);
    if (value && value.length === 17) {
      const { data } = await decodeVinNhtsa(value);
      if (data.Results[0] && data.Results[0].Make) {
        setFieldValue(
          "description",
          `${data.Results[0].ModelYear} ${data.Results[0].Make} ${data.Results[0].Model}`
        );
      }
    }
  };
  const toggleDocumentsModal = () => {
    setOpenDocModal(!openDocModal);
  };

  const user = getCurrentUser();
  const isClient = user?.role === UserRoles.CLIENT;
  const sendMail = (mails: string = "", subject: string, body: string) => {
    window.location.href =`mailto:${mails}?subject=${subject}&body=${body}`
  }
  const mails = vehicleData?.users && vehicleData?.users.map((user: IUser) => {
    return user.email
  }).join(';')
  const expectedDateEn = dayjs(vehicleData?.expectedDate).format("MMMM DD, YYYY")
  const expectedDateDe = dayjs(vehicleData?.expectedDate).locale("de").format("DD.MM.YYYY")
  const expectedDatePl = dayjs(vehicleData?.expectedDate).locale("pl").format("DD.MM.YYYY")
  const sendArrivalMail = () => {
    var vin = vehicleData?.vin.slice(-6);
    const subject = encodeURIComponent(`Arrival notice: ${vehicleData?.description} #${vin}`)
    let body = encodeURIComponent(
`Good Day,

Your shipment ${vehicleData?.description} #${vin} is estimated to arrive in Bremerhaven on ${expectedDateEn}.
Prior the scheduled arrival at the port, we kindly request that you submit the necessary customs documents through our system.
    `); 

    if(!vehicleData?.users || vehicleData?.users.length === 0) {
      sendMail("", subject, body);
      return
    }
    if(vehicleData?.users[0].language === "deDE") {
      body = encodeURIComponent(
`Guten Tag,

Die voraussichtliche Ankunft Ihrer Sendung, ${vehicleData?.description} #${vin}, ist für den ${expectedDateDe} in Bremerhaven geplant.
Vor der geplanten Schiffsankunft möchten wir Sie darum bitten, die erforderlichen Zolldokumente über unser System zu übermitteln.
      `);
    }
    if( vehicleData?.users[0].language === "plPL") {
      body = encodeURIComponent(
`Dzień dobry,

Twoja przesyłka ${vehicleData?.description} #${vin} ma szacowaną datę przybycia do Bremerhaven na ${expectedDatePl}
Przed planowaną datą wpłynięcia statku do portu, prosimy o dołączenie za pośrednictwem naszego systemu wymaganych dokumentów do procedury celnej.
      `)
    }
    sendMail(mails, subject, body)
  }

  

  const sendUnloadingMail = () => {
    if(!vehicleData?.vin) {
      return;
    }
   
    var vin = vehicleData?.vin.slice(-6);
    const { statusHistory } = vehicleData || {};
    const unloadedStatus = statusHistory?.find((item: StatusHistory) => {
      return item.status === "UNLOADED"
    })
    if(!unloadedStatus) {
      toast.error(t("Vehicle does not have unloading date!"));
      return;
    }

    const expectedDateEn = dayjs(unloadedStatus?.date).format("MMMM DD, YYYY")
    const expectedDateDe = dayjs(unloadedStatus?.date).locale("de").format("DD.MM.YYYY")
    const expectedDatePl = dayjs(unloadedStatus?.date).locale("pl").format("DD.MM.YYYY")

    const subject = encodeURIComponent(`Unloaded: ${vehicleData?.description} #${vin}`)
    let body = encodeURIComponent(
`Good Day,

Your shipment was unloaded on ${expectedDateEn}.
${vehicleData?.description} #${vin}

Warehouse address: 
Motus Shipping GmbH & Co. KG, Weissenstein 9, 27574 Bremerhaven

Please ensure that all necessary documents for the customs process have been provided and that your shipment has been released by the shipper. 

You can track the current status of your shipment on your profile.
     `)
     if(!vehicleData?.users || vehicleData?.users.length === 0) {
      sendMail("", subject, body);
      return
    }
    if(vehicleData?.users[0].language === "deDE") {
      body = encodeURIComponent(
`Guten Tag,

Ihre Sendung wurde am ${expectedDateDe} entladen.
${vehicleData?.description} #${vin}

Adresse des Lagers: 
Motus Shipping GmbH & Co. KG, Weissenstein 9, 27574 Bremerhaven

Bitte stellen Sie sicher, dass alle für den Zollprozess benötigten Unterlagen eingereicht wurden und dass Ihre Sendung vom Versender freigegeben wurde. 

Den aktuellen Status Ihrer Sendung können Sie in Ihrem Profil verfolgen.`)
    }
     
    if(vehicleData?.users[0].language === "plPL") {
      body = encodeURIComponent(
`Dzień dobry,

Twoja przesyłka została rozładowana dnia ${expectedDatePl}.
${vehicleData?.description} #${vin}

Adres magazynu:
Motus Shipping GmbH & Co. KG, Weissenstein 9, 27574 Bremerhaven

Upewnij się, że wszystkie dokumenty do procesu celnego zostały dostarczone i że Twoja przesyłka została zwolniona przez nadawcę.

Aktualny status przesyłki możesz obserwować na swoim profilu.
`)
    }
    sendMail(mails, subject, body)
  }

  const sendCustomsEmail = () => {
    var vin = vehicleData?.vin.slice(-6);
    const subject = encodeURIComponent(`Customs procedure completed: ${vehicleData?.description} #${vin}`)
    let body = encodeURIComponent(
`Good day,

We would like to inform you that customs procedures for your shipment have been completed.
You will find an invoice for payment on your profile.

Prior to arranging the pickup, please verify on your profile that our invoice has been settled and the shipment has been released by the shipper.
    `);

    if(!vehicleData?.users || vehicleData?.users.length === 0) {
      sendMail("", subject, body);
      return
    }

    if(vehicleData?.users[0].language === "deDE") {
      body = encodeURIComponent(
`Sehr geehrte Damen und Herren,

wir möchten Sie darüber informieren, dass die Zollformalitäten für Ihre Sendung abgeschlossen wurden.
Auf Ihrem Profil finden Sie eine Rechnung zur Begleichung.

Bevor Sie die Abholung organisieren, bitten wir Sie freundlich, sicherzustellen, dass unsere Rechnung beglichen wurde und die Ware vom Versender freigegeben ist.`);
    }
     
    if(vehicleData?.users[0].language === "plPL") {
      body = encodeURIComponent(  
`Dzień dobry,

Informujemy, że procedury celne dla Twojej przesyłki zostały zakończone.
Na swoim profilu znajdziesz rachunek do pobrania.

Przed zorganizowaniem odbioru prosimy uprzejmie o sprawdzenie na swoim profilu, czy nasza faktura jest uregulowana, a towar zwolniony przez nadawcę.`)
    }
    sendMail(mails, subject, body)
  }

  const hasData = Object.keys(vehicleData || []).length !== 0;
  return (
    <Formik
      validateOnChange={false}
      validationSchema={validationSchema}
      enableReinitialize
      innerRef={formRef}
      initialValues={
        vehicleData && Object.keys(vehicleData).length > 0
          ? {
              ...vehicleData,
              userId: vehicleData.user?._id,
              warehouseId: vehicleData.warehouse?._id,
              documents: {
                technicalReport: vehicleData?.technicalReport,
              },
              otherFiles:
                vehicleData && vehicleData.otherDocuments
                  ? Object.keys(vehicleData.otherDocuments).map((doc: any) => {
                      return {
                        name: doc,
                        file:
                          vehicleData.otherDocuments &&
                          vehicleData.otherDocuments[doc].link,
                      };
                    })
                  : [{ name: "", file: "" }],
            }
          : initialValues
      }
      onSubmit={async (values: IVehicle) => {
        handleSubmit({
          ...values,
        });
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
      }) => (
        <Form autoComplete="off">
          {hasData && (<>
          <FormDivider variant="fullWidth">{t("Actions")}</FormDivider>
          <Grid container spacing={4}>
            <Grid item xs={12} md={2}>
              <Button onClick={sendArrivalMail} variant="contained" component="label">
                {t("Send arrival date e-mail")}
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button onClick={sendUnloadingMail} variant="contained" component="label">
                {t("Send unloading date e-mail")}
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button onClick={sendCustomsEmail} variant="contained" component="label">
                {t("Customs procedure completed")}
              </Button>
            </Grid>

            <Grid item xs={12} md={6}>
              {/* <GoogleDriveGallery /> */}
            </Grid>
          </Grid>
          </>)}
          <FormDivider variant="fullWidth">{t("Vehicle information")}</FormDivider>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <MultipleUserSelect
                label={t("Customers")}
                required
                handleChange={setFieldValue}
                values={vehicleData?.users ? vehicleData.users : undefined}
                error={Boolean(errors.user)}
              />
            </Grid>
            {user?.role !== UserRoles.CLIENT && (
              <Grid item xs={12} md={6}>
                <UserSelect
                  required={false}
                  role={[UserRoles.EMPLOYEE]}
                  label={t("Select user")}
                  handleChange={(user) =>
                    setFieldValue("warehouseId", user?.id)
                  }
                  values={vehicleData?.warehouse}
                  error={Boolean(errors.warehouseId)}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TextFieldWrapper
                inputProps={{
                  maxLength: 50,
                }}
                multiline
                size="small"
                fullWidth
                id="description"
                name="description"
                label={t("Description")}
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.description)}
                helperText={errors.description}
              ></TextFieldWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldWrapper
                inputProps={{
                  maxLength: 17,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {values.vin && (
                        <IconButton onClick={() => decodeVin(values.vin)}>
                          <VisibilityIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="vin"
                name="vin"
                label={t("VIN")}
                value={values.vin?.toUpperCase()}
                onChange={(props: MuiTextFieldProps) => {
                  handleVinChange(props, setFieldValue);
                }}
                onBlur={handleBlur}
                error={Boolean(errors.vin)}
                helperText={errors.vin}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                select
                size="small"
                fullWidth
                id="type"
                name="type"
                label={t("Type")}
                required
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.type)}
                helperText={errors.type}
              >
                {Object.keys(VehicleTypes).map((key) => {
                  return (
                    <MenuItem id={key} key={key} value={key}>
                      {t(VehicleTypes[key])}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      transform: "scale(1.2)",
                    }}
                    size="medium"
                    onChange={(event) => {
                      setFieldValue("released", event.target.checked);
                    }}
                    checked={values.released}
                  />
                }
                label={t("Released")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      transform: "scale(1.2)",
                    }}
                    size="medium"
                    onChange={(event) => {
                      setFieldValue("onhold", event.target.checked);
                    }}
                    checked={values.onhold}
                  />
                }
                label={t("On hold")}
              />
            </Grid>
          </Grid>
          {/* Documents and images */}
          <Grid container spacing={4}>
            <Grid sx={{ marginTop: 10 }} item xs={12} md={6}>
              <Button
                onClick={toggleDocumentsModal}
                variant="contained"
                component="label"
              >
                {t("Documents")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <GoogleDriveGallery /> */}
            </Grid>
          </Grid>
          <FormDivider variant="fullWidth">{t("Title information")}</FormDivider>
          <VehicleTitleFields
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={values}
          />
          <FormDivider variant="fullWidth">{t("Customs")}</FormDivider>
          <VehicleShippingFields
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={values}
          />
          <FormDivider variant="fullWidth">{t("Status History")}</FormDivider>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <FieldArray
                name="statusHistory"
                render={(arrayHelpers) => (
                  <div>
                    {values.statusHistory?.map((status: any, index: number) => (
                      <Grid
                        style={{ paddingBottom: 10 }}
                        gap={1}
                        container
                        key={index}
                      >
                        {/** both these conventions do the same */}
                        <Grid item xs={12} md={3}>
                          <TextField
                            label="Status"
                            fullWidth
                            select
                            size="small"
                            value={values.statusHistory[index].status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`statusHistory[${index}].status`}
                          >
                            {(
                              Object.keys(VehicleStatus) as Array<
                                keyof typeof VehicleStatus
                              >
                            ).map((key) => {
                              return (
                                <MenuItem key={key} value={key}>
                                  {t(VehicleStatus[key])}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              renderInput={(params) => (
                                <TextFieldWrapper
                                  fullWidth
                                  size="small"
                                  {...params}
                                />
                              )}
                              label={t("Date")}
                              inputFormat="DD/MM/YYYY"
                              value={values.statusHistory[index].date}
                              onChange={(value) => {
                                setFieldValue(
                                  `statusHistory[${index}].date`,
                                  value
                                );
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextFieldWrapper
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            id={`statusHistory[${index}].note`}
                            name={`statusHistory[${index}].note`}
                            label={t("Note")}
                            value={values.statusHistory[index].note}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          {index > 0 && (
                            <IconButton
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <RemoveIcon fontSize="medium" />
                            </IconButton>
                          )}
                          {index + 1 === values?.statusHistory.length && (
                            <IconButton
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  status: "",
                                  date: dayjs(Date.now()).format("YYYY-MM-DD"),
                                })
                              }
                            >
                              <AddIcon fontSize="medium" />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                )}
              />
            </Grid>
          </Grid>

          <FormDivider variant="fullWidth">{t("Notes")}</FormDivider>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <TextFieldWrapper
                multiline
                disabled
                size="small"
                inputProps={{
                  maxLength: 300,
                }}
                fullWidth
                id="notes"
                name="customerNotes"
                label={t("Customer notes")}
                value={values.customerNotes}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.customerNotes)}
                helperText={errors.customerNotes}
              ></TextFieldWrapper>
            </Grid>
            <Grid item xs={12} md={12}>
              <TextFieldWrapper
                multiline
                size="small"
                inputProps={{
                  maxLength: 300,
                }}
                fullWidth
                id="notes"
                name="notes"
                label={t("Notes")}
                value={values.notes}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.notes)}
                helperText={errors.notes}
              ></TextFieldWrapper>
            </Grid>
            {!isClient && (
              <Grid item xs={12} md={12}>
                <TextFieldWrapper
                  multiline
                  size="small"
                  inputProps={{
                    maxLength: 300,
                  }}
                  fullWidth
                  id="adminNotes"
                  name="adminNotes"
                  label={t("Internal notes")}
                  value={values.adminNotes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.adminNotes)}
                  helperText={errors.adminNotes}
                ></TextFieldWrapper>
              </Grid>
            )}
            <Grid item xs={12} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      transform: "scale(1.2)",
                    }}
                    size="medium"
                    onChange={(event) => {
                      setFieldValue("paid", event.target.checked);
                    }}
                    checked={values.paid}
                  />
                }
                label={t("Paid")}
              />
            </Grid>
          </Grid>
          <VehicleDocuments
            open={openDocModal}
            handleClose={toggleDocumentsModal}
          />
          <FormDivider variant="fullWidth">{t("Pickup Information")}</FormDivider>
          <VehiclePickupInformation
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            values={values}
          />
        </Form>
      )}
    </Formik>
  );
};
